import axiosInstance from "./axiosInstance";

export const getAllKurumsal = async () => {
  try {
    const response = await axiosInstance.get("/kurumsal");
    return response.data;
  } catch (error) {
    console.error("error getting kurumsal all: ", error);
  }
};

export const getKurumsal = async (id) => {
  try {
    const response = await axiosInstance.get(`/kurumsal/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting kurumsal: ${id}`, error);
  }
};

export const storeKurumsal = async (kurumsalData) => {
  try {
    const response = await axiosInstance.post("/kurumsal", kurumsalData);
    return response;
  } catch (error) {
    console.error(`error getting kurumsal store: `, error);
  }
};

export const putKurumsal = async (id, kurumsalData) => {
  try {
    const response = await axiosInstance.put(`/kurumsal/${id}`, kurumsalData);
    return response;
  } catch (error) {
    console.error(`error getting kurumsal update: ${id}`, error);
  }
};

export const putRangeKurumsal = async (kurumsalData) => {
  try {
    const response = await axiosInstance.put(
      `/kurumsal/range/update`,
      kurumsalData
    );
    return response;
  } catch (error) {
    console.error(`error getting kurumsal range update:`, error);
  }
};

export const deleteKurumsal = async (id) => {
  try {
    const response = await axiosInstance.delete(`/kurumsal/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting kurumsal delete: ${id}`, error);
  }
};
