import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  getSitebilgileri,
  putSitebilgileri,
} from "../../services/sitebilgileriService";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";

const SponsorBannerAlanlari = () => {
  const toast = useRef(null);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await getSitebilgileri();
      if (response) {
        setSettings(response[0]);
      }
    };

    fetchSettings();
  }, []);

  const customBase64Uploader = async (event, type) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        if (type == "footersponsorresim") {
          setSettings({
            ...settings,
            footersponsorresim: base64data,
          });
        }

        if (type == "sidebarsponsorresim") {
          setSettings({
            ...settings,
            sidebarsponsorresim: base64data,
          });
        }

        if (type == "anasayfa1sponsorresim") {
          setSettings({
            ...settings,
            anasayfa1sponsorresim: base64data,
          });
        }

        if (type == "anasayfa2sponsorresim") {
          setSettings({
            ...settings,
            anasayfa2sponsorresim: base64data,
          });
        }
      }
    };
  };

  const submitSettings = async (settingsData) => {
    const response = await putSitebilgileri(settingsData.id, settingsData);
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Güncelleme Başarılı!",
        life: 1000,
      });
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="surface-section px-4 py-2 md:px-4 lg:px-4">
        <div className="grid">
          <div className="col-12">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">
                  Ana Sayfa Sponsor 1 - Resim
                </label>
                <br />
                <Image
                  src={
                    settings && settings.anasayfa1sponsorresim
                      ? settings.anasayfa1sponsorresim
                      : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "anasayfa1sponsorresim")
                    }
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        anasayfa1sponsorresim: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        anasayfa1sponsorresim: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="anasayfa1sponsorbaslik"
                  className="font-medium text-900">
                  Ana Sayfa Sponsor 1 - Başlık
                </label>
                <InputText
                  value={
                    settings && settings.anasayfa1sponsorbaslik
                      ? settings.anasayfa1sponsorbaslik
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      anasayfa1sponsorbaslik: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="anasayfa1sponsorlink"
                  className="font-medium text-900">
                  Ana Sayfa Sponsor 1 - URL
                </label>
                <InputText
                  value={
                    settings && settings.anasayfa1sponsorlink
                      ? settings.anasayfa1sponsorlink
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      anasayfa1sponsorlink: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <hr />
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">
                  Ana Sayfa Sponsor 2 - Resim
                </label>
                <br />
                <Image
                  src={
                    settings && settings.anasayfa2sponsorresim
                      ? settings.anasayfa2sponsorresim
                      : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "anasayfa2sponsorresim")
                    }
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        anasayfa2sponsorresim: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        anasayfa2sponsorresim: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="anasayfa2sponsorbaslik"
                  className="font-medium text-900">
                  Ana Sayfa Sponsor 2 - Başlık
                </label>
                <InputText
                  value={
                    settings && settings.anasayfa2sponsorbaslik
                      ? settings.anasayfa2sponsorbaslik
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      anasayfa2sponsorbaslik: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="anasayfa2sponsorlink"
                  className="font-medium text-900">
                  Ana Sayfa Sponsor 2 - URL
                </label>
                <InputText
                  value={
                    settings && settings.anasayfa2sponsorlink
                      ? settings.anasayfa2sponsorlink
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      anasayfa2sponsorlink: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <hr />
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">
                  Footer Sponsor - Resim
                </label>
                <br />
                <Image
                  src={
                    settings && settings.footersponsorresim
                      ? settings.footersponsorresim
                      : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "footersponsorresim")
                    }
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        footersponsorresim: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        footersponsorresim: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="footersponsorbaslik"
                  className="font-medium text-900">
                  Footer Sponsor - Başlık
                </label>
                <InputText
                  value={
                    settings && settings.footersponsorbaslik
                      ? settings.footersponsorbaslik
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      footersponsorbaslik: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="footersponsorlink"
                  className="font-medium text-900">
                  Footer Sponsor - URL
                </label>
                <InputText
                  value={
                    settings && settings.footersponsorlink
                      ? settings.footersponsorlink
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      footersponsorlink: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <hr />
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">
                  Sidebar Sponsor - Resim
                </label>
                <br />
                <Image
                  src={
                    settings && settings.sidebarsponsorresim
                      ? settings.sidebarsponsorresim
                      : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "sidebarsponsorresim")
                    }
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        sidebarsponsorresim: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        sidebarsponsorresim: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="sidebarsponsorbaslik"
                  className="font-medium text-900">
                  Sidebar Sponsor - Başlık
                </label>
                <InputText
                  value={
                    settings && settings.sidebarsponsorbaslik
                      ? settings.sidebarsponsorbaslik
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      sidebarsponsorbaslik: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label
                  htmlFor="sidebarsponsorlink"
                  className="font-medium text-900">
                  Sidebar Sponsor - URL
                </label>
                <InputText
                  value={
                    settings && settings.sidebarsponsorlink
                      ? settings.sidebarsponsorlink
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      sidebarsponsorlink: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <hr />
              </div>
              <div className="col-12">
                <Button
                  label="Kaydet"
                  onClick={() => {
                    submitSettings(settings);
                  }}
                  className="w-auto mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SponsorBannerAlanlari;
