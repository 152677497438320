import axiosInstance from "./axiosInstance";

export const getAllForm = async () => {
  try {
    const response = await axiosInstance.get("/form");
    return response.data;
  } catch (error) {
    console.error("error getting form all: ", error);
  }
};

export const getForm = async (id) => {
  try {
    const response = await axiosInstance.get(`/form/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting form: ${id}`, error);
  }
};

export const putForm = async (id, formData) => {
  try {
    const response = await axiosInstance.put(`/form/${id}`, formData);
    return response;
  } catch (error) {
    console.error(`error getting form update: ${id}`, error);
  }
};

export const deleteForm = async (id) => {
  try {
    const response = await axiosInstance.delete(`/form/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting form delete: ${id}`, error);
  }
};
