import axiosInstance from "./axiosInstance";

export const getAllTarihce = async () => {
  try {
    const response = await axiosInstance.get("/tarihce");
    return response.data;
  } catch (error) {
    console.error("error getting tarihçe all: ", error);
  }
};

export const getTarihce = async (id) => {
  try {
    const response = await axiosInstance.get(`/tarihce/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting tarihçe: ${id}`, error);
  }
};

export const storeTarihce = async (tarihceData) => {
  try {
    const response = await axiosInstance.post("/tarihce", tarihceData);
    return response;
  } catch (error) {
    console.error(`error getting tarihçe store: `, error);
  }
};

export const putTarihce = async (id, tarihceData) => {
  try {
    const response = await axiosInstance.put(`/tarihce/${id}`, tarihceData);
    return response;
  } catch (error) {
    console.error(`error getting tarihçe update: ${id}`, error);
  }
};

export const putRangeTarihce = async (tarihceData) => {
  try {
    const response = await axiosInstance.put(
      `/tarihce/range/update`,
      tarihceData
    );
    return response;
  } catch (error) {
    console.error(`error getting tarihçe range update:`, error);
  }
};

export const deleteTarihce = async (id) => {
  try {
    const response = await axiosInstance.delete(`/tarihce/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting tarihçe delete: ${id}`, error);
  }
};
