import React, { Fragment, useEffect, useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import {
  getSitebilgileri,
  putSitebilgileri,
} from "../../services/sitebilgileriService";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { InputSwitch } from "primereact/inputswitch";

const PopUpBanner = () => {
  const toast = useRef(null);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await getSitebilgileri();
      if (response) {
        setSettings(response[0]);
      }
    };

    fetchSettings();
  }, []);

  const customBase64Uploader = async (event, type) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        if (type == "popupresim") {
          setSettings({
            ...settings,
            popupresim: base64data,
          });
        }
      }
    };
  };

  const submitSettings = async (settingsData) => {
    const response = await putSitebilgileri(settingsData.id, settingsData);
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Güncelleme Başarılı!",
        life: 1000,
      });
    }
  };
  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="surface-section px-4 py-2 md:px-4 lg:px-4">
        <div className="grid">
          <div className="col-12">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <label className="font-medium text-900 mb-2">
                  Pop-Up Aktiflik
                </label>
                <br />
                <InputSwitch
                  checked={settings && settings.popupstatus == 1 ? true : false}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      popupstatus: e.value,
                    });
                  }}
                />
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Pop-Up Resim
                </label>
                <br />
                <Image
                  src={
                    settings && settings.popupresim ? settings.popupresim : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "popupresim")
                    }
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        popupresim: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        popupresim: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12 lg:col-6">
                <label className="font-medium text-900">Başlık</label>
                <InputText
                  value={
                    settings && settings.popupbaslik
                      ? settings.popupbaslik
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      popupbaslik: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 lg:col-6">
                <label className="font-medium text-900">Link</label>
                <InputText
                  value={
                    settings && settings.popuplink ? settings.popuplink : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      popuplink: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">Açıklama</label>
                <InputTextarea
                  value={
                    settings && settings.popupyazi ? settings.popupyazi : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      popupyazi: e.target.value,
                    });
                  }}
                  type="text"
                  rows={4}
                  autoResize
                />
              </div>
              <div className="col-12">
                <Button
                  label="Kaydet"
                  onClick={() => {
                    submitSettings(settings);
                  }}
                  className="w-auto mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PopUpBanner;
