import { jwtDecode } from "jwt-decode";
const tokenManager = {
  isTokenExpired(token) {
    const jwtToken = token || window.localStorage.getItem("token");
    if (!jwtToken) return true;
    const decodedToken = jwtDecode(jwtToken);
    if (decodedToken.role != "admin") return true;
    return Date.now() >= decodedToken.exp * 1000;
  },

  addToken(token) {
    if (!this.isTokenExpired(token)) {
      const decodedToken = jwtDecode(token);
      const user = {
        id: decodedToken.id,
        name: decodedToken.name,
        email: decodedToken.email,
        role: decodedToken.role,
      };
      window.localStorage.setItem("user", JSON.stringify(user));
      window.localStorage.setItem("token", token);
    }
  },

  isTokenValid() {
    const jwtToken = window.localStorage.getItem("token");
    return jwtToken && !this.isTokenExpired(jwtToken);
  },

  getUser() {
    const user = window.localStorage.getItem("user");
    return user ? JSON.parse(user) : null;
  },

  clear(reload) {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("token");
    if (reload) {
      window.location.reload();
    }
  },
};

export default tokenManager;
