// const devApiBaseUrl = "http://127.0.0.1:8000/api/";
const devApiBaseUrl = "https://api.izmirbesiktaslilardernegi.org.tr/api/";
const productionApiBaseUrl =
  "https://api.izmirbesiktaslilardernegi.org.tr/api/";
const devRootUrl = "http://localhost:3000/";
const productionRootUrl = "https://panel.izmirbesiktaslilardernegi.org.tr/";

const isLocalHost = window.location.hostname === "localhost";

export const appConfig = {
  apiBaseUrl: isLocalHost ? devApiBaseUrl : productionApiBaseUrl,
  routeUrl: isLocalHost ? devRootUrl : productionRootUrl,
  apiRoute: (route) => {
    return `${isLocalHost ? devApiBaseUrl : productionApiBaseUrl}${route}`;
  },
};

export default appConfig;
