import React, { useState, useEffect, Fragment, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  getAllUsers,
  getUsersFiltersAll,
  putUsers,
  storeUsers,
} from "../../services/usersService";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Toolbar } from "primereact/toolbar";
import { Badge } from "primereact/badge";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const Users = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryFilters = queryParams.get("filters");
  const [users, setUsers] = useState(null);
  const [filterCount, setFilterCount] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [dataTableRows, setDataTableRows] = useState(10);
  const [first, setFirst] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [uyelerBaslik, setUyelerBaslik] = useState("Üyeler");
  const navigate = useNavigate();
  const toast = useRef(null);
  const [filters, setFilters] = useState({
    isBorcluUyeler: false,
  });
  const [expandedRows, setExpandedRows] = useState(null);

  const yasArray = () => {
    const ageArray = [];
    for (let index = 18; index <= 90; index++) {
      ageArray.push({ label: `${index}`, value: `${index}` });
    }
    return ageArray;
  };

  useEffect(() => {
    refresh();
  }, [location.search]);

  const refresh = async () => {
    setIsLoading(true);
    const fetchUsers = async () => {
      let response;
      if (queryFilters === "borcluuyeler") {
        response = await getUsersFiltersAll({
          deletestatus: 0,
          isBorcluUyeler: true,
        });
        if (response) {
          setUsers(response.users);
          setFilterCount(response.count);
          setIsLoading(false);
          setFilters({ isBorcluUyeler: true });
          setUyelerBaslik("Borçlu Üyeler");
        }
      } else if (queryFilters === "yenikayitolanuyeler") {
        response = await getUsersFiltersAll({
          deletestatus: 0,
          isOnaylananKullanici: "Hayır",
        });
        if (response) {
          setUsers(response.users);
          setFilterCount(response.count);
          setIsLoading(false);
          setFilters({ isOnaylananKullanici: "Hayır" });
          setUyelerBaslik("Yeni Kayıt Olan Üyeler");
        }
      } else if (queryFilters === "dernektarafindansilinenuyeler") {
        response = await getUsersFiltersAll({
          isSilinenKullanici: "dernektarafındansilinen",
        });
        if (response) {
          setUsers(response.users);
          setFilterCount(response.count);
          setIsLoading(false);
          setFilters({ isSilinenKullanici: "dernektarafındansilinen" });
          setUyelerBaslik("Dernek Tarafından Silinen Üyeler");
        }
      } else if (queryFilters === "kullanicitarafindansilinenuyeler") {
        response = await getUsersFiltersAll({
          isSilinenKullanici: "kullanıcıtarafındansilinen",
        });
        if (response) {
          setUsers(response.users);
          setFilterCount(response.count);
          setIsLoading(false);
          setFilters({ isSilinenKullanici: "kullanıcıtarafındansilinen" });
          setUyelerBaslik("Kullanıcı Tarafından Silinen Üyeler");
        }
      } else {
        // response = await getAllUsers({ isSilinenKullanici: "Evet" });
        response = await getAllUsers();
        if (response) {
          setUsers(response);
          setFilterCount(response.length);
          setIsLoading(false);
          setFilters({ isBorcluUyeler: false });
          setUyelerBaslik("Üyeler");
        }
      }
    };

    fetchUsers();
  };

  const submitUsersler = async (userslerData) => {
    const updatedData = { ...userslerData };
    if (updatedData.password === "") {
      delete updatedData.password;
    }

    if (!userslerData.id) {
      const response = await storeUsers(userslerData);
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Kayıt Eklendi!",
          life: 1000,
        });
      }
    } else {
      const response = await putUsers(userslerData.id, userslerData);
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Güncelleme Başarılı!",
          life: 1000,
        });
        refresh();
      }
    }
  };

  const statusTemplate = (rowData) => {
    return (
      <div>
        <InputSwitch
          checked={rowData.status === 1}
          onChange={(e) => {
            if (rowData) {
              const updatedUsers = {
                ...rowData,
                status: e.value,
              };
              submitUsersler(updatedUsers);
            }
          }}
        />
      </div>
    );
  };

  const deleteItem = (rowData) => {
    const accept = async () => {
      const updatedRowData = { ...rowData, deletestatus: 1 };
      const response = await submitUsersler(updatedRowData);
      if (response) {
        toast.current.show({
          severity: "info",
          summary: "Silindi",
          detail: "Üye Başarıyla Kaldırıldı.",
          life: 3000,
        });
        refresh();
      }
    };

    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "İptal Edildi",
        detail: "Silme İşlemi İptal Edildi.",
        life: 3000,
      });
    };

    confirmDialog({
      group: "templating",
      header: "İşlemi Onayla",
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-times text-6xl text-red-500"></i>
          <span className="text-center">
            Üye sistemden kalıcı olarak silinecektir. <br />
            Bu işlem geri alınamaz. <br /> Emin misiniz?
          </span>
        </div>
      ),
      accept,
      reject,
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      acceptClassName: "bg-red-500",
    });
  };

  const recoveryItem = (rowData) => {
    const accept = async () => {
      const updatedRowData = { ...rowData, deletestatus: 0 };
      const response = await submitUsersler(updatedRowData);
      if (response) {
        toast.current.show({
          severity: "info",
          summary: "Geri Yüklendi",
          detail: "Üye Başarıyla Geri Döndürüldü.",
          life: 3000,
        });
        refresh();
      }
    };

    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "İptal Edildi",
        detail: "Geri Yükleme İşlemi İptal Edildi.",
        life: 3000,
      });
    };

    confirmDialog({
      group: "templating",
      header: "İşlemi Onayla",
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-refresh text-6xl text-green-500"></i>
          <span className="text-center">
            Üye geri döndürülecektir. <br />
            Emin misiniz?
          </span>
        </div>
      ),
      accept,
      reject,
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      acceptClassName: "bg-green-500",
    });
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-eye"
          className="mr-1"
          severity="primary"
          onClick={() => {
            navigate(`/kullanicilar/${rowData.id}/`);
          }}
        />
        {rowData.deletestatus === 1 || rowData.deletestatus === 2 ? (
          <Button
            icon="pi pi-refresh"
            severity="success"
            onClick={() => recoveryItem(rowData)}
          />
        ) : (
          <Button
            icon="pi pi-trash"
            severity="danger"
            onClick={() => deleteItem(rowData)}
          />
        )}
      </div>
    );
  };

  const nameBadge = (rowData) => {
    if (rowData.deletestatus === 1) {
      return (
        <Fragment>
          {rowData.name}
          <Badge value={"Dernek Sildi"} severity={"danger"} className="ml-1" />
        </Fragment>
      );
    } else if (rowData.deletestatus === 2) {
      return (
        <Fragment>
          {rowData.name}
          <Badge
            value={"Kullanıcı Sildi"}
            severity={"danger"}
            className="ml-1"
          />
        </Fragment>
      );
    } else {
      return rowData.name;
    }
  };

  const statusTemplateAidatlar = (rowData) => {
    return (
      <div>
        <Badge
          value={rowData && rowData.status === 1 ? "Ödendi" : "Ödenmedi"}
          severity={rowData && rowData.status === 1 ? "success" : "danger"}
        />
      </div>
    );
  };

  const statusTemplateAidatlarDekont = (rowData) => {
    return (
      <div>
        <Badge
          value={rowData && rowData.dekontpdf ? "Yüklendi" : "Yüklenmedi"}
          severity={rowData && rowData.dekontpdf ? "success" : "danger"}
        />
      </div>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.aidatlar.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div>
        <DataTable value={data.aidatlar}>
          <Column field="donem" header="Dönem" sortable></Column>
          <Column field="tur" header="Aidat Türü" sortable></Column>
          <Column field="fiyat" header="Ücret" sortable></Column>
          <Column
            field="status"
            header="Ödenme Durumu"
            body={statusTemplateAidatlar}
          />
          <Column header="Dekont" body={statusTemplateAidatlarDekont} />
        </DataTable>
      </div>
    );
  };

  const exportToExcel = () => {
    let html = "<table border='1'>";
    html += "<thead><tr>";
    html += "<th>Üye No</th>";
    html += "<th>Karar No</th>";
    html += "<th>Kongre No</th>";
    html += "<th>Ad Soyad</th>";
    html += "<th>Telefon</th>";
    html += "<th>E-Mail</th>";
    html += "<th>T.C. Kimlik No</th>";
    html += "<th>Doğum Yeri</th>";
    html += "<th>Doğum Tarihi</th>";
    html += "<th>Baba Adı</th>";
    html += "<th>Son Mezun Okul/Bölüm</th>";
    html += "<th>Meslek</th>";
    html += "<th>Ev Adresi</th>";
    html += "<th>İş Adresi</th>";
    html += "<th>Sosyal Medya Hesabı</th>";
    html += "<th>Referans Kişi</th>";
    html += "<th>IBAN No</th>";
    html += "<th>IBAN Ad Soyad</th>";
    html += "<th>Kullanıcı Onay Durumu</th>";
    html += "<th>Kullanıcı Aktifliği</th>";
    html += "</tr></thead>";
    html += "<tbody>";
    users.forEach((user) => {
      html += "<tr>";
      html += `<td>${user.uyeno ?? "-"}</td>`;
      html += `<td>${user.kararno ?? "-"}</td>`;
      html += `<td>${user.kongreno ?? "-"}</td>`;
      html += `<td>${user.name ?? "-"}</td>`;
      html += `<td>${user.telefon ?? "-"}</td>`;
      html += `<td>${user.email ?? "-"}</td>`;
      html += `<td>${user.tckimlikno ?? "-"}</td>`;
      html += `<td>${user.dogumyeri ?? "-"}</td>`;
      html += `<td>${user.dogumtarihi ?? "-"}</td>`;
      html += `<td>${user.babaadi ?? "-"}</td>`;
      html += `<td>${user.sonmezunokulbolum ?? "-"}</td>`;
      html += `<td>${user.meslek ?? "-"}</td>`;
      html += `<td>${user.evadresi ?? "-"}</td>`;
      html += `<td>${user.isadresi ?? "-"}</td>`;
      html += `<td>${user.sosyalmedyalink ?? "-"}</td>`;
      html += `<td>${user.referanskisi ?? "-"}</td>`;
      html += `<td>${user.ibanno ?? "-"}</td>`;
      html += `<td>${user.ibanadsoyad ?? "-"}</td>`;
      html += `<td>${user.status === 1 ? "Onaylandı" : "Onaylandı"}</td>`;
      html += `<td>${user.deletestatus === 1 ? "Silindi" : "Silinmedi"}</td>`;
      html += "</tr>";
    });
    html += "</tbody></table>";

    // Convert HTML string to worksheet
    const worksheet = XLSX.utils.table_to_sheet(
      new DOMParser().parseFromString(html, "text/html").querySelector("table")
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, "users.xlsx");
  };

  const dataTableHeader = (
    <Fragment>
      <div className="flex align-items-center justify-content-between">
        <div>
          <span>{filterCount} kayıt bulundu.</span>
        </div>
        <div>
          <Button
            label="Filtrele"
            icon="pi pi-filter"
            className="mr-2"
            onClick={async () => {
              const response = await getUsersFiltersAll(filters);
              if (response) {
                setUsers(response.users);
                setFilterCount(response.count);
                toast.current.show({
                  severity: "success",
                  summary: "Success",
                  detail: "Kayıtlar listelendi!",
                  life: 1000,
                });
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: "Kayıt bulunamadı!",
                  life: 1000,
                });
              }
            }}
          />
          <Button
            label="Filtreyi Temizle"
            className="mr-2"
            icon="pi pi-trash"
            severity="danger"
            onClick={() => {
              refresh();
              setFilters(null);
            }}
            disabled={!filters}
          />
          <Button
            label="Yenile"
            severity="secondary"
            icon="pi pi-refresh"
            className="mr-2"
            onClick={() => {
              setGlobalFilterValue(" ");
              setDataTableRows(10);
              setFirst(0);
              setCurrentPage(0);
              setSortField(null);
              setSortOrder(null);
              refresh();
            }}
          />
          <Button
            label="Excel"
            icon="pi pi-file-excel"
            className="mr-2 p-button-success"
            onClick={exportToExcel}
          />
          <InputText
            type="search"
            placeholder="Arama yap..."
            size="30"
            onChange={(e) => {
              setGlobalFilterValue(e.target.value);
            }}
            value={globalFilterValue ? globalFilterValue : ""}
          />
        </div>
      </div>
    </Fragment>
  );

  const filterArea = (
    <Fragment>
      <div className="grid">
        <div className="p-col col-12 lg:col-2">
          <div className="card flex justify-content-center">
            <Dropdown
              className="w-full"
              value={filters && filters.minYas ? filters.minYas : ""}
              options={yasArray()}
              onChange={(e) => setFilters({ ...filters, minYas: e.value })}
              placeholder="Min Yaş"
              filter
            />
          </div>
        </div>
        <div className="p-col col-12 lg:col-2">
          <div className="card flex justify-content-center">
            <Dropdown
              className="w-full"
              value={filters && filters.maxYas ? filters.maxYas : ""}
              options={yasArray()}
              onChange={(e) => setFilters({ ...filters, maxYas: e.value })}
              placeholder="Max Yaş"
              filter
            />
          </div>
        </div>

        <div className="p-col col-12 lg:col-2">
          <div className="card flex justify-content-center">
            <Dropdown
              className="w-full"
              value={
                filters && filters.isOnaylananKullanici
                  ? filters.isOnaylananKullanici
                  : ""
              }
              options={[
                { label: "Hayır", value: "Hayır" },
                { label: "Evet", value: "Evet" },
              ]}
              onChange={(e) =>
                setFilters({ ...filters, isOnaylananKullanici: e.value })
              }
              placeholder="Onaylanan Kullanıcıları Listele"
            />
          </div>
        </div>
        <div className="p-col col-12 lg:col-2">
          <div className="card flex justify-content-center">
            <Dropdown
              className="w-full"
              value={
                filters && filters.isSilinenKullanici
                  ? filters.isSilinenKullanici
                  : ""
              }
              options={[
                {
                  label: "Dernek Tarafından Silinen",
                  value: "dernektarafındansilinen",
                },
                {
                  label: "Kullanıcı Tarafından Silinen",
                  value: "kullanıcıtarafındansilinen",
                },
                { label: "Aktif Üyeleri Göster", value: "Aktif Üyeler" },
              ]}
              onChange={(e) =>
                setFilters({ ...filters, isSilinenKullanici: e.value })
              }
              placeholder="Silinen Kullanıcıları Listele"
            />
          </div>
        </div>
        <div className="p-col col-12 lg:col-2">
          <div className="card flex justify-content-center">
            <Dropdown
              className="w-full"
              value={
                filters && filters.isKongreUyesi ? filters.isKongreUyesi : ""
              }
              options={[
                { label: "Hayır", value: "Hayır" },
                { label: "Evet", value: "Evet" },
              ]}
              onChange={(e) =>
                setFilters({ ...filters, isKongreUyesi: e.value })
              }
              placeholder="Kongre Üyelerini Listele"
            />
          </div>
        </div>
        <div className="p-col col-12 lg:col-2 flex align-items-center">
          <label
            onClick={() =>
              setFilters({
                ...filters,
                isBorcluUyeler: !filters.isBorcluUyeler,
              })
            }
            style={{ fontSize: 14 }}>
            Borçlu Üyeleri Listele
          </label>
          <InputSwitch
            className="lg:ml-2 ml-0"
            checked={filters && filters.isBorcluUyeler}
            onChange={(e) =>
              setFilters({ ...filters, isBorcluUyeler: e.value })
            }
          />
        </div>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog group="templating" />
      <div className="py-2">
        <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
          <h3 style={{ color: "rgb(108, 117, 125)" }}>{uyelerBaslik}</h3>
        </div>
      </div>
      <div className="grid">
        <div className="col-12 lg:col-12">{filterArea}</div>
      </div>
      <div className="card">
        <DataTable
          value={users}
          reorderableRows
          header={dataTableHeader}
          globalFilter={globalFilterValue}
          paginator
          first={first}
          rows={dataTableRows}
          sortField={sortField}
          sortOrder={sortOrder}
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={isLoading}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          onPage={(e) => {
            setFirst(e.first);
            setDataTableRows(e.rows);
            setCurrentPage(e.page);
          }}
          onSort={(e) => {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder);
          }}>
          <Column expander={allowExpansion} style={{ width: "5rem" }} />
          <Column field="uyeno" header="Üye No" sortable />
          <Column field="kararno" header="Karar No" sortable />
          <Column field="kongreno" header="Kongre No" sortable />
          <Column field="name" header="Ad Soyad" body={nameBadge} sortable />
          <Column field="telefon" header="Telefon" sortable />
          <Column field="email" header="E-Mail" sortable />
          <Column field="referanskisi" header="Referans Kişi" sortable />
          <Column
            field="status"
            header="Onay Durumu"
            body={statusTemplate}
            sortable
          />
          <Column header="İşlem" body={actionTemplate} />
        </DataTable>
      </div>
    </Fragment>
  );
};

export default Users;
