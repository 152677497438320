import React, { useState, useEffect, Fragment, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  getAllBildirimler,
  putBildirimler,
  storeBildirimler,
  deleteBildirimler,
} from "../../services/bildirimlerService";
import { getAllUsers } from "../../services/usersService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

const Bildirimler = () => {
  const [bildirimler, setBildirimler] = useState([]);
  const [selectedBildirimler, setSelectedBildirimler] = useState({
    bildirim_turu: "",
    baslik: "",
    aciklama: "",
    user_id: [],
  });
  const [users, setUsers] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const response = await getAllUsers();
    if (response) {
      const allUsers = response.map((user) => ({
        label: user.name,
        value: user.id,
      }));
      setUsers([{ label: "Tümünü Seç", value: "all" }, ...allUsers]);
    }
  };

  const refresh = async () => {
    setIsLoading(true);
    const fetchBlogs = async () => {
      const response = await getAllBildirimler();
      if (response) {
        setBildirimler(response);
        setIsLoading(false);
      }
    };

    fetchBlogs();
  };

  const submitBildirimler = async (bildirimlerData) => {
    if (!Array.isArray(bildirimlerData.user_id)) {
      bildirimlerData.user_id = [bildirimlerData.user_id];
    }

    if (bildirimlerData.user_id.includes("all")) {
      const allUserIds = users
        .filter((user) => user.value !== "all")
        .map((user) => user.value);
      bildirimlerData.user_id = allUserIds;
    }

    if (!bildirimlerData.id) {
      const response = await storeBildirimler(bildirimlerData);
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Kayıt Eklendi!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setSelectedBildirimler({
          bildirim_turu: "",
          baslik: "",
          aciklama: "",
          user_id: [],
        });
      }
    } else {
      const response = await putBildirimler(
        bildirimlerData.id,
        bildirimlerData
      );
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Güncelleme Başarılı!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setSelectedBildirimler({
          bildirim_turu: "",
          baslik: "",
          aciklama: "",
          user_id: [],
        });
      }
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-eye"
          className="mr-1"
          severity="primary"
          onClick={() => {
            setSelectedBildirimler(rowData);
            setVisibleDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          onClick={() => deleteItem(rowData)}
        />
      </div>
    );
  };

  const deleteItem = (rowData) => {
    const accept = async () => {
      const response = await deleteBildirimler(rowData.id);
      if (response) {
        toast.current.show({
          severity: "info",
          summary: "Silindi",
          detail: "Kayıt Başarıyla Kaldırıldı.",
          life: 3000,
        });
        refresh();
      }
    };

    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "İptal Edildi",
        detail: "Silme İşlemi İptal Edildi.",
        life: 3000,
      });
    };

    confirmDialog({
      group: "templating",
      header: "İşlemi Onayla",
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-times text-6xl text-red-500"></i>
          <span>İçerik sistemden kalıcı olarak silinecektir.</span>
        </div>
      ),
      accept,
      reject,
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      acceptClassName: "bg-red-500",
    });
  };

  const dataTableHeader = (
    <Fragment>
      <div className="flex align-items-center justify-content-between">
        <div>
          <span>{bildirimler.length} kayıt bulundu.</span>
        </div>
        <div>
          <InputText
            type="search"
            placeholder="Arama yap..."
            size="30"
            onInput={(e) => {
              setGlobalFilterValue(e.target.value);
            }}
          />
        </div>
      </div>
    </Fragment>
  );

  const indexTemplate = (rowData, column) => {
    return column.rowIndex + 1;
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog group="templating" />
      <div className="py-2">
        <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
          <h3 style={{ color: "rgb(108, 117, 125)" }}>Bildirimler</h3>
          <div className="mt-3 md:mt-0">
            <Button
              label="Yeni Bildirim Ekle"
              className="p-button-outlined mr-2"
              icon="pi pi-plus"
              onClick={() => {
                setVisibleDialog(true);
                setSelectedBildirimler({
                  bildirim_turu: "",
                  baslik: "",
                  aciklama: "",
                  user_id: [],
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={bildirimler}
          header={dataTableHeader}
          globalFilter={globalFilterValue}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={isLoading}>
          <Column header="Sıra No" body={indexTemplate} sortable />
          <Column field="bildirim_turu" header="Bildirim Türü" />
          <Column field="baslik" header="Başlık" />
          <Column field="aciklama" header="Açıklama" />
          <Column header="İşlem" body={actionTemplate} />
        </DataTable>
      </div>
      <Dialog
        header={
          selectedBildirimler && selectedBildirimler.id
            ? "Bildirim Görüntüle"
            : "Bildirim Ekle"
        }
        visible={visibleDialog}
        onHide={() => {
          setVisibleDialog(false);
          setSelectedBildirimler({
            bildirim_turu: "",
            baslik: "",
            aciklama: "",
            user_id: [],
          });
        }}
        maximizable
        resizable={false}
        style={{ width: "75vw" }}>
        <div className="px-4 py-2 md:px-4 lg:px-4">
          <div className="grid">
            <div className="col-12">
              <div className="grid formgrid p-fluid">
                <div className="field mb-4 col-12">
                  <label htmlFor="baslik" className="font-medium text-900">
                    Bildirim Türü
                  </label>
                  <Dropdown
                    className="w-full"
                    value={selectedBildirimler.bildirim_turu}
                    options={[
                      { label: "Duyuru", value: "Duyuru" },
                      { label: "Hatırlatma", value: "Hatırlatma" },
                      { label: "Doğum Günü", value: "Doğum Günü" },
                    ]}
                    onChange={(e) =>
                      setSelectedBildirimler({
                        ...selectedBildirimler,
                        bildirim_turu: e.value,
                      })
                    }
                    placeholder="Bildirim Türü"
                    disabled={selectedBildirimler.id}
                  />
                </div>
                <div className="field mb-4 col-12">
                  <label htmlFor="users" className="font-medium text-900">
                    Kullanıcı Seç
                  </label>
                  <Dropdown
                    className="w-full"
                    value={selectedBildirimler.user_id}
                    options={users}
                    onChange={(e) => {
                      let newValue = e.value;
                      if (Array.isArray(newValue) && newValue.includes("all")) {
                        newValue = users
                          .filter((user) => user.value !== "all")
                          .map((user) => user.value);
                      }
                      setSelectedBildirimler({
                        ...selectedBildirimler,
                        user_id: newValue,
                      });
                    }}
                    placeholder="Kullanıcı Seç"
                    multiple
                  />
                </div>
                <div className="field mb-4 col-12">
                  <label htmlFor="baslik" className="font-medium text-900">
                    Başlık
                  </label>
                  <InputText
                    value={selectedBildirimler.baslik}
                    onChange={(e) => {
                      setSelectedBildirimler({
                        ...selectedBildirimler,
                        baslik: e.target.value,
                      });
                    }}
                    type="text"
                    disabled={selectedBildirimler.id}
                  />
                </div>
                <div className="field mb-4 col-12">
                  <label className="font-medium text-900">Açıklama</label>
                  <InputTextarea
                    value={selectedBildirimler.aciklama}
                    onChange={(e) => {
                      setSelectedBildirimler({
                        ...selectedBildirimler,
                        aciklama: e.target.value,
                      });
                    }}
                    type="text"
                    rows={4}
                    autoResize
                    disabled={selectedBildirimler.id}
                  />
                </div>
                <div className="col-12">
                  <Button
                    label="Kaydet"
                    className="w-auto mt-3"
                    disabled={selectedBildirimler.id}
                    onClick={() => {
                      submitBildirimler(selectedBildirimler);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default Bildirimler;
