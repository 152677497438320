import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import {
  getSitebilgileri,
  putSitebilgileri,
} from "../../services/sitebilgileriService";
import {
  getAllSmtpMail,
  storeSmtpMail,
  deleteSmtpMail,
} from "../../services/smtpmailService";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const SmtpAyarlari = () => {
  const toast = useRef(null);
  const [settings, setSettings] = useState(null);
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState({ email: "" });

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await getSitebilgileri();
      if (response) {
        setSettings(response[0]);
      }
    };

    fetchSettings();
  }, []);

  const refresh = async () => {
    const fetchEmails = async () => {
      const response = await getAllSmtpMail();
      if (response) {
        setEmails(response);
      }
    };

    fetchEmails();
  };

  const submitSettings = async (settingsData) => {
    const response = await putSitebilgileri(settingsData.id, settingsData);
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Güncelleme Başarılı!",
        life: 1000,
      });
    }
  };

  const submitSmtpMail = async () => {
    const response = await storeSmtpMail(newEmail);
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Kayıt Eklendi!",
        life: 1000,
      });
      setNewEmail({ email: "" });
      refresh();
    }
  };

  const deleteItem = (rowData) => {
    const accept = async () => {
      const response = await deleteSmtpMail(rowData.id);
      if (response) {
        toast.current.show({
          severity: "info",
          summary: "Silindi",
          detail: "Kayıt Başarıyla Kaldırıldı.",
          life: 3000,
        });
        // Veritabanından silinmiş olan öğeyi state'den de kaldır
        setEmails((prevEmails) =>
          prevEmails.filter((email) => email.id !== rowData.id)
        );
      }
    };

    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "İptal Edildi",
        detail: "Silme İşlemi İptal Edildi.",
        life: 3000,
      });
    };

    confirmDialog({
      group: "templating",
      header: "İşlemi Onayla",
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-times text-6xl text-red-500"></i>
          <span>İçerik sistemden kalıcı olarak silinecektir.</span>
        </div>
      ),
      accept,
      reject,
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      acceptClassName: "bg-red-500",
    });
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog group="templating" />
      <div className="surface-section px-4 py-2 md:px-4 lg:px-4">
        <div className="surface-card shadow-2 border-round p-4 mb-4">
          <div className="flex justify-content-between align-items-center mb-5">
            <span className="text-xl text-900 font-medium">
              Kayıtlı E-Mailler
            </span>
          </div>
          <ul className="list-none p-0 m-0">
            {emails &&
              emails.length > 0 &&
              emails.map((email, index) => (
                <li
                  key={index}
                  className="flex flex-column md:flex-row md:align-items-center md:justify-content-between pb-2 mb-4 border-bottom-1"
                  style={{ borderColor: "var(--surface-300)" }}>
                  <div className="flex">
                    <div className="mr-0 md:mr-8">
                      <span className="block text-900 font-medium mb-1">
                        {email.email}
                      </span>
                    </div>
                  </div>
                  <div className="mt-2 md:mt-0 flex flex-nowrap">
                    <Button
                      className="p-button-text p-button-plain p-button-rounded mr-1"
                      icon="pi pi-trash"
                      label="Sil"
                      style={{ color: "var(--red-500)" }}
                      onClick={() => deleteItem(email)}
                    />
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <div className="grid">
          <div className="col-12">
            <div className="grid formgrid p-fluid">
              <div className="field col-12 lg:col-10">
                <InputText
                  value={newEmail.email}
                  onChange={(e) => {
                    setNewEmail({ email: e.target.value });
                  }}
                  type="text"
                  placeholder="Yeni E-Mail adresi yazınız..."
                  required
                />
              </div>
              <div className="col-12 lg:col-2">
                <Button
                  label="Ekle"
                  disabled={!newEmail.email}
                  onClick={submitSmtpMail}
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="grid">
          <div className="col-12">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <label htmlFor="smtpauth" className="font-medium text-900">
                  Auth
                </label>
                <InputText
                  value={settings && settings.smtpauth ? settings.smtpauth : ""}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      smtpauth: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="smtpsecure" className="font-medium text-900">
                  Secure
                </label>
                <InputText
                  value={
                    settings && settings.smtpsecure ? settings.smtpsecure : ""
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      smtpsecure: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="smtphost" className="font-medium text-900">
                  Host
                </label>
                <InputText
                  value={settings && settings.smtphost ? settings.smtphost : ""}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      smtphost: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="smtpport" className="font-medium text-900">
                  Port
                </label>
                <InputText
                  value={settings && settings.smtpport ? settings.smtpport : ""}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      smtpport: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="smtpemail" className="font-medium text-900">
                  E-Mail
                </label>
                <InputText
                  value={
                    settings && settings.smtpemail ? settings.smtpemail : ""
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      smtpemail: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="smtpsifre" className="font-medium text-900">
                  Şifre
                </label>
                <InputText
                  value={
                    settings && settings.smtpsifre ? settings.smtpsifre : ""
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      smtpsifre: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="col-12">
                <Button
                  label="Kaydet"
                  onClick={() => {
                    submitSettings(settings);
                  }}
                  className="w-auto mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SmtpAyarlari;
