import React, { useRef, useEffect, useState } from "react";
import { Badge } from "primereact/badge";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import tokenManager from "../utils/managers/tokenManager";
import { useNavigate, useLocation } from "react-router-dom";

const Header = () => {
  const btnRef1 = useRef(null);
  const btnRef2 = useRef(null);
  const btnRef3 = useRef(null);
  const btnRef30 = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const user = tokenManager.getUser();

  // State to manage visibility of menus
  const [isMenuVisible, setMenuVisible] = useState({
    menu1: false,
    menu2: false,
    menu3: false,
    userMenu: false,
  });

  // Close all menus when route changes
  useEffect(() => {
    setMenuVisible({
      menu1: false,
      menu2: false,
      menu3: false,
      userMenu: false,
    });
  }, [location]);

  return (
    <div
      className="surface-overlay py-3 px-6 lg:px-8 shadow-2 flex align-items-center justify-content-between relative lg:static"
      style={{ minHeight: "80px" }}>
      <img
        src="https://izmirbesiktaslilardernegi.org.tr/yedekdosyalar/img/upload/sabit-bilgiler/2642626051.png"
        alt="bastion-700"
        height={50}
        className="mr-0 lg:mr-6"
      />
      <StyleClass
        nodeRef={btnRef1}
        selector="@next"
        enterClassName="hidden"
        leaveToClassName="hidden"
        hideOnOutsideClick>
        <a
          ref={btnRef1}
          className="cursor-pointer block lg:hidden text-700"
          onClick={() =>
            setMenuVisible((prevState) => ({
              ...prevState,
              menu1: !prevState.menu1,
            }))
          }>
          <i className="pi pi-bars text-4xl"></i>
          <Ripple />
        </a>
      </StyleClass>
      <div className="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
        <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
          <li
            onClick={() => {
              navigate("/");
            }}>
            <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <i className="pi pi-home mr-2"></i>
              <span>Dashboard</span>
              <Ripple />
            </a>
          </li>
          <li
            onClick={() => {
              navigate("/site-bilgileri");
            }}>
            <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <i className="pi pi-cog mr-2"></i>
              <span>Ayarlar</span>
              <Ripple />
            </a>
          </li>
          <li className="lg:relative">
            <StyleClass
              nodeRef={btnRef2}
              selector="@next"
              enterClassName="hidden"
              enterActiveClassName="scalein"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeout"
              hideOnOutsideClick>
              <a
                ref={btnRef2}
                className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                onClick={() =>
                  setMenuVisible((prevState) => ({
                    ...prevState,
                    menu2: !prevState.menu2,
                  }))
                }>
                <i className="pi pi-folder-open mr-2"></i>
                <span>İçerikler</span>
                <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                <Ripple />
              </a>
            </StyleClass>
            <ul
              className={`list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadowpi-user-2 lg:border-1 border-50 lg:absolute surface-overlay ${
                isMenuVisible.menu2 ? "" : "hidden"
              } origin-top w-full lg:w-15rem cursor-pointer`}>
              <li
                onClick={() => {
                  navigate("/slider");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Slider</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/blog");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Blog</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/blog-yazarlar");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Blog Yazarlar</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/dernekten-haberler");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Haberler</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/kan-kampanyalari");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Kan Kampanyaları</span>
                  <Ripple />
                </a>
              </li>
              {/* <li
                onClick={() => {
                  navigate("/hakkimizda");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Hakkımızda</span>
                  <Ripple />
                </a>
              </li> */}
              <li
                onClick={() => {
                  navigate("/kunye");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Ana Sayfa Sıralı Kutular</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/kurumsal");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Kurumsal</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/header-menu");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Header Menü</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/footer-menu");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Footer Menü</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/sabit-sayfalar");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Sabit Sayfalar</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/sponsorlarimiz");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Sponsorlarımız</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/sponsorluklar");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Sponsorluklar</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/tarihce");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Tarihçe</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/yk-ve-ku-uyeleri");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Yönetim Kurulu Üyeleri</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/uyelik-formlari");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Üyelik Formları</span>
                  <Ripple />
                </a>
              </li>
            </ul>
          </li>
          <li className="lg:relative">
            <StyleClass
              nodeRef={btnRef3}
              selector="@next"
              enterClassName="hidden"
              enterActiveClassName="scalein"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeout"
              hideOnOutsideClick>
              <a
                ref={btnRef3}
                className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                onClick={() =>
                  setMenuVisible((prevState) => ({
                    ...prevState,
                    menu3: !prevState.menu3,
                  }))
                }>
                <i className="pi pi-users mr-2"></i>
                <span>Üyeler</span>
                <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                <Ripple />
              </a>
            </StyleClass>
            <ul
              className={`list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadowpi-user-2 lg:border-1 border-50 lg:absolute surface-overlay ${
                isMenuVisible.menu3 ? "" : "hidden"
              } origin-top w-full lg:w-15rem cursor-pointer`}>
              <li
                onClick={() => {
                  navigate("/kullanicilar");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Tüm Üyeler</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/kullanicilar?filters=borcluuyeler");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Borçlu Üyeler</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate("/kullanicilar?filters=yenikayitolanuyeler");
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">Yeni Kayıt Olan Üyeler</span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate(
                    "/kullanicilar?filters=dernektarafindansilinenuyeler"
                  );
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">
                    Dernek Tarafından Silinen Üyeler
                  </span>
                  <Ripple />
                </a>
              </li>
              <li
                onClick={() => {
                  navigate(
                    "/kullanicilar?filters=kullanicitarafindansilinenuyeler"
                  );
                }}>
                <a className="p-ripple flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 w-full">
                  <span className="font-medium">
                    Kullanıcı Tarafından Silinen Üyeler
                  </span>
                  <Ripple />
                </a>
              </li>
            </ul>
          </li>
          <li
            onClick={() => {
              navigate("/yoneticiler");
            }}>
            <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <i className="pi pi-user mr-2"></i>
              <span>Yöneticiler</span>
              <Ripple />
            </a>
          </li>
          <li
            onClick={() => {
              navigate("/bagislar");
            }}>
            <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <i className="pi pi-money-bill mr-2"></i>
              <span>Bağışlar</span>
              <Ripple />
            </a>
          </li>
          <li
            onClick={() => {
              navigate("/bildirimler");
            }}>
            <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <i className="pi pi-bell mr-2"></i>
              <span>Bildirimler</span>
              <Ripple />
            </a>
          </li>
          <li
            onClick={() => {
              navigate("/iletisim-formlari");
            }}>
            <a className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
              <i className="pi pi-slack mr-2"></i>
              <span>Formlar</span>
              <Ripple />
            </a>
          </li>
        </ul>
        <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
          <li className="h-full relative">
            <StyleClass
              nodeRef={btnRef30}
              selector="@next"
              enterClassName="hidden"
              enterActiveClassName="scalein"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeout"
              hideOnOutsideClick>
              <a
                ref={btnRef30}
                className="p-ripple cursor-pointer h-full inline-flex align-items-center text-600 hover:text-900 py-3 lg:py-0 px-3 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent transition-colors transition-duration-150"
                onClick={() =>
                  setMenuVisible((prevState) => ({
                    ...prevState,
                    userMenu: !prevState.userMenu,
                  }))
                }>
                <i className="pi pi-user text-base lg:text-2xl mr-2 lg:mr-0"></i>
                <span className="mx-2 font-medium text-900">
                  {user && user.name ? user.name : null}
                </span>
                <i className="pi pi-angle-down"></i>
                <Ripple />
              </a>
            </StyleClass>
            <div
              className={`hidden static lg:absolute w-full surface-overlay left-0 top-100 z-1 shadow-none lg:shadow-2 origin-top border-round pl-3 lg:pl-0 ${
                isMenuVisible.userMenu ? "" : "hidden"
              }`}>
              <ul className="list-none p-0 m-0">
                <li
                  onClick={() => {
                    tokenManager.clear(true);
                  }}>
                  <a className="p-ripple cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 border-transparent hover:border-500 transition-colors transition-duration-150 p-3">
                    <span className="pi pi-sign-out mr-2"></span>
                    <span className="font-medium">Çıkış Yap</span>
                    <Ripple />
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
