import React, { Fragment, useEffect, useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import {
  getSitebilgileri,
  putSitebilgileri,
} from "../../services/sitebilgileriService";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";

const FiyatBilgileri = () => {
  const toast = useRef(null);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await getSitebilgileri();
      if (response) {
        setSettings(response[0]);
      }
    };

    fetchSettings();
  }, []);

  const submitSettings = async (settingsData) => {
    const response = await putSitebilgileri(settingsData.id, settingsData);
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Güncelleme Başarılı!",
        life: 1000,
      });
    }
  };
  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="surface-section px-4 py-2 md:px-4 lg:px-4">
        <div className="grid">
          <div className="col-12">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12 md:col-4">
                <label className="font-medium text-900">Giriş Aidatı</label>
                <InputText
                  value={
                    settings && settings.girisaidati
                      ? settings.girisaidati
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      girisaidati: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-4">
                <label className="font-medium text-900">Yıllık Aidat</label>
                <InputText
                  value={
                    settings && settings.yillikaidat
                      ? settings.yillikaidat
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      yillikaidat: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-4">
                <label className="font-medium text-900">
                  Banka Komisyon Oranı(%'lik cinsinden)
                </label>
                <InputText
                  value={
                    settings && settings.bankakomisyonorani
                      ? settings.bankakomisyonorani
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      bankakomisyonorani: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="col-12">
                <Button
                  label="Kaydet"
                  onClick={() => {
                    submitSettings(settings);
                  }}
                  className="w-auto mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FiyatBilgileri;
