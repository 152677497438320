import axiosInstance from "./axiosInstance";

export const getAllSmtpMail = async () => {
  try {
    const response = await axiosInstance.get("/smtp-mail");
    return response.data;
  } catch (error) {
    console.error("error getting smtp mail all: ", error);
  }
};

export const getSmtpMail = async (id) => {
  try {
    const response = await axiosInstance.get(`/smtp-mail/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting smtp mail: ${id}`, error);
  }
};

export const storeSmtpMail = async (smtpMailData) => {
  try {
    const response = await axiosInstance.post("/smtp-mail", smtpMailData);
    return response;
  } catch (error) {
    console.error(`error getting smtp mail store: `, error);
  }
};

export const deleteSmtpMail = async (id) => {
  try {
    const response = await axiosInstance.delete(`/smtp-mail/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting smtp mail delete: ${id}`, error);
  }
};
