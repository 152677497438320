import React, { Fragment, useEffect, useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { getHakkimizda, putHakkimizda } from "../../services/hakkimizdaService";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";
import { Editor } from "primereact/editor";

const Hakkimizda = () => {
  const [selectedHakkimizda, setSelectedHakkimizda] = useState(null);
  const toast = useRef(null);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    const fetchData = async () => {
      const response = await getHakkimizda();
      if (response) {
        setSelectedHakkimizda(response[0]);
      }
    };

    fetchData();
  };

  const customBase64Uploader = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedHakkimizda({
          ...selectedHakkimizda,
          resim1: base64data,
        });
      }
    };
  };

  const submit = async (data) => {
    const response = await putHakkimizda(data.id, data);
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Güncelleme Başarılı!",
        life: 1000,
      });
      refresh();
    }
  };
  return (
    <Fragment>
      <Toast ref={toast} />
      <h3 style={{ color: "rgb(108, 117, 125)" }}>Hakkımızda</h3>
      <div className="surface-section px-4 py-2 md:px-4 lg:px-4">
        <div className="grid">
          <div className="col-12">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Resim 1
                </label>
                <br />
                <Image
                  src={
                    selectedHakkimizda && selectedHakkimizda.resim1
                      ? selectedHakkimizda.resim1
                      : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={customBase64Uploader}
                    onRemove={() => {
                      setSelectedHakkimizda({
                        ...selectedHakkimizda,
                        resim1: null,
                      });
                    }}
                    onClear={() => {
                      setSelectedHakkimizda({
                        ...selectedHakkimizda,
                        resim1: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Resim 2
                </label>
                <br />
                <Image
                  src={
                    selectedHakkimizda && selectedHakkimizda.resim2
                      ? selectedHakkimizda.resim2
                      : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={customBase64Uploader}
                    onRemove={() => {
                      setSelectedHakkimizda({
                        ...selectedHakkimizda,
                        resim2: null,
                      });
                    }}
                    onClear={() => {
                      setSelectedHakkimizda({
                        ...selectedHakkimizda,
                        resim2: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">Başlık</label>
                <InputText
                  value={
                    selectedHakkimizda && selectedHakkimizda.baslik
                      ? selectedHakkimizda.baslik
                      : null
                  }
                  onChange={(e) => {
                    setSelectedHakkimizda({
                      ...selectedHakkimizda,
                      baslik: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="yazi" className="font-medium text-900">
                  Yazı
                </label>
                <Editor
                  autoResize={false}
                  style={{
                    width: "100%",
                    minHeight: 150,
                    resize: "vertical",
                    overflow: "auto",
                  }}
                  value={
                    selectedHakkimizda && selectedHakkimizda.yazi
                      ? selectedHakkimizda.yazi
                      : null
                  }
                  onTextChange={(e) => {
                    setSelectedHakkimizda({
                      ...selectedHakkimizda,
                      yazi: e.htmlValue,
                    });
                  }}
                />
              </div>
              <div className="col-12">
                <Button
                  label="Kaydet"
                  className="w-auto mt-3"
                  onClick={() => {
                    submit(selectedHakkimizda);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Hakkimizda;
