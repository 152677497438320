import axiosInstance from "./axiosInstance";

export const getAllBildirimler = async () => {
  try {
    const response = await axiosInstance.get("/bildirimler/admin");
    return response.data;
  } catch (error) {
    console.error("error getting bildirimler all: ", error);
  }
};

export const getBildirimler = async (id) => {
  try {
    const response = await axiosInstance.get(`/bildirimler/admin/${id}`);
    return response.data;
  } catch (error) {
    console.error(`error getting bildirimler: ${id}`, error);
  }
};

export const storeBildirimler = async (bildirimlerData) => {
  try {
    const response = await axiosInstance.post(
      "/bildirimler/admin",
      bildirimlerData
    );
    return response;
  } catch (error) {
    console.error(`error getting bildirimler store: `, error);
  }
};

export const putBildirimler = async (id, bildirimlerData) => {
  try {
    const response = await axiosInstance.put(
      `/bildirimler/admin/${id}`,
      bildirimlerData
    );
    return response;
  } catch (error) {
    console.error(`error getting bildirimler update: ${id}`, error);
  }
};

export const deleteBildirimler = async (id) => {
  try {
    const response = await axiosInstance.delete(`/bildirimler/admin/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting bildirimler delete: ${id}`, error);
  }
};
