import React, { Fragment, useEffect, useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { getUsers, putUsers } from "../../services/usersService";
import { useParams } from "react-router-dom";

const Belgeler = () => {
  const toast = useRef(null);
  const [user, setUser] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchUser = async () => {
      const response = await getUsers(id);
      if (response) {
        setUser(response);
      }
    };

    fetchUser();
  }, []);

  const customBase64Uploader = async (event, type) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        if (type == "adlisicilkaydi") {
          setUser({
            ...user,
            adlisicilkaydi: base64data,
          });
        }

        if (type == "vesikalik") {
          setUser({
            ...user,
            vesikalik: base64data,
          });
        }

        if (type == "nufusfotokopi") {
          setUser({
            ...user,
            nufusfotokopi: base64data,
          });
        }
      }
    };
  };

  const submitUser = async (userData) => {
    const response = await putUsers(userData.id, userData);
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Güncelleme Başarılı!",
        life: 1000,
      });
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="surface-section px-4 py-2 md:px-4 lg:px-4">
        <div className="grid">
          <div className="col-12">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Adli Sicil Kaydı
                </label>
                <br />
                {user && user.adlisicilkaydi ? (
                  <Fragment>
                    <a
                      className="p-button"
                      icon="pi pi-check"
                      href={
                        user && user.adlisicilkaydi ? user.adlisicilkaydi : null
                      }
                      download
                      style={{
                        width: 90,
                        textDecoration: "none",
                      }}>
                      <i className="pi pi-download mr-1"></i> İndir
                    </a>
                    <br />
                  </Fragment>
                ) : null}
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="pdf/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="PDF Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "adlisicilkaydi")
                    }
                    onRemove={() => {
                      setUser({
                        ...user,
                        adlisicilkaydi: null,
                      });
                    }}
                    onClear={() => {
                      setUser({
                        ...user,
                        adlisicilkaydi: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>

              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Vesikalık
                </label>
                <br />
                {user && user.vesikalik ? (
                  <Fragment>
                    <a
                      className="p-button"
                      icon="pi pi-check"
                      href={user && user.vesikalik ? user.vesikalik : null}
                      download
                      style={{
                        width: 90,
                        textDecoration: "none",
                      }}>
                      <i className="pi pi-download mr-1"></i> İndir
                    </a>
                    <br />
                  </Fragment>
                ) : null}
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="PDF Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "vesikalik")
                    }
                    onRemove={() => {
                      setUser({
                        ...user,
                        vesikalik: null,
                      });
                    }}
                    onClear={() => {
                      setUser({
                        ...user,
                        vesikalik: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Nüfus Fotokopi
                </label>
                <br />
                {user && user.nufusfotokopi ? (
                  <Fragment>
                    <a
                      className="p-button"
                      icon="pi pi-check"
                      href={
                        user && user.nufusfotokopi ? user.nufusfotokopi : null
                      }
                      download
                      style={{
                        width: 90,
                        textDecoration: "none",
                      }}>
                      <i className="pi pi-download mr-1"></i> İndir
                    </a>
                    <br />
                  </Fragment>
                ) : null}
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="pdf/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="PDF Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "nufusfotokopi")
                    }
                    onRemove={() => {
                      setUser({
                        ...user,
                        nufusfotokopi: null,
                      });
                    }}
                    onClear={() => {
                      setUser({
                        ...user,
                        nufusfotokopi: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="col-12">
                <Button
                  label="Kaydet"
                  onClick={() => {
                    submitUser(user);
                  }}
                  className="w-auto mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Belgeler;
