import axiosInstance from "./axiosInstance";

export const getAllSabitSayfalar = async () => {
  try {
    const response = await axiosInstance.get("/sabit-sayfalar");
    return response.data;
  } catch (error) {
    console.error("error getting sabit sayfalar all: ", error);
  }
};

export const getSabitSayfalar = async (id) => {
  try {
    const response = await axiosInstance.get(`/sabit-sayfalar/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting sabit sayfalar: ${id}`, error);
  }
};

export const storeSabitSayfalar = async (sabitsayfalarData) => {
  try {
    const response = await axiosInstance.post(
      "/sabit-sayfalar",
      sabitsayfalarData
    );
    return response;
  } catch (error) {
    console.error(`error getting sabit sayfalar store: `, error);
  }
};

export const putSabitSayfalar = async (id, sabitsayfalarData) => {
  try {
    const response = await axiosInstance.put(
      `/sabit-sayfalar/${id}`,
      sabitsayfalarData
    );
    return response;
  } catch (error) {
    console.error(`error getting sabit sayfalar update: ${id}`, error);
  }
};

export const putRangeSabitSayfalar = async (menuData) => {
  try {
    const response = await axiosInstance.put(
      `/sabit-sayfalar/range/update`,
      menuData
    );
    return response;
  } catch (error) {
    console.error(`error getting sabit sayfalar range update:`, error);
  }
};

export const deleteSabitSayfalar = async (id) => {
  try {
    const response = await axiosInstance.delete(`/sabit-sayfalar/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting sabit sayfalar delete: ${id}`, error);
  }
};
