import React, { useState, useEffect, Fragment, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  getAllBagislar,
  putBagislar,
  storeBagislar,
  deleteBagislar,
} from "../../services/bagislarService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { Image } from "primereact/image";
import moment from "moment";
import { Badge } from "primereact/badge";

const Bagislar = () => {
  const [bagislar, setBagislar] = useState(null);
  const [selectedBagislar, setSelectedBagislar] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [toplamBagisTutari, setToplamBagisTutari] = useState(null);
  const [toplamBagisSayisi, setToplamBagisSayisi] = useState(null);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    setIsLoading(true);
    const fetchBagislar = async () => {
      const response = await getAllBagislar();
      if (response.bagislar) {
        setBagislar(response.bagislar);
        setToplamBagisTutari(response.bagis_yapilan_tutar);
        setToplamBagisSayisi(response.bagis_sayisi);
        setIsLoading(false);
      }
    };

    fetchBagislar();
  };

  const customBase64Uploader = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedBagislar({
          ...selectedBagislar,
          dekontpdf: base64data,
        });
      }
    };
  };

  const submitBagislar = async (bagislarData) => {
    if (!bagislarData.id) {
      const response = await storeBagislar(bagislarData);
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Kayıt Eklendi!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setSelectedBagislar(null);
      }
    } else {
      const response = await putBagislar(bagislarData.id, bagislarData);
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Güncelleme Başarılı!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setSelectedBagislar(null);
      }
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="mr-1"
          severity="primary"
          onClick={() => {
            setSelectedBagislar(rowData);
            setVisibleDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          onClick={() => deleteItem(rowData)}
        />
      </div>
    );
  };

  const templateDekont = (rowData) => {
    return (
      <div>
        <Badge
          value={rowData && rowData.dekontpdf ? "Yüklendi" : "Yüklenmedi"}
          severity={rowData && rowData.dekontpdf ? "success" : "danger"}
        />
      </div>
    );
  };

  const sayiHesapla = (rowData, rowIndex) => {
    return rowIndex + 1;
  };

  const deleteItem = (rowData) => {
    const accept = async () => {
      const response = await deleteBagislar(rowData.id);
      if (response) {
        toast.current.show({
          severity: "info",
          summary: "Silindi",
          detail: "Kayıt Başarıyla Kaldırıldı.",
          life: 3000,
        });
        refresh();
      }
    };

    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "İptal Edildi",
        detail: "Silme İşlemi İptal Edildi.",
        life: 3000,
      });
    };

    confirmDialog({
      group: "templating",
      header: "İşlemi Onayla",
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-times text-6xl text-red-500"></i>
          <span>İçerik sistemden kalıcı olarak silinecektir.</span>
        </div>
      ),
      accept,
      reject,
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      acceptClassName: "bg-red-500",
    });
  };

  const dataTableHeader = (
    <Fragment>
      <div className="flex align-items-center justify-content-between">
        <div>
          <span>{bagislar ? bagislar.length : 0} kayıt bulundu.</span>
        </div>
        <div>
          <InputText
            type="search"
            placeholder="Arama yap..."
            size="30"
            onInput={(e) => {
              setGlobalFilterValue(e.target.value);
            }}
          />
        </div>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog group="templating" />
      <div className="py-2">
        <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
          <h3 style={{ color: "rgb(108, 117, 125)" }}>Bağışlar</h3>
        </div>
      </div>

      <div className="grid">
        <div className="col-12 md:col-6 lg:col-6 p-3">
          <div
            className="p-3 text-center bg-white-alpha-90 shadow-3"
            style={{ borderRadius: "12px" }}>
            <span
              className="inline-flex justify-content-center align-items-center bg-black-alpha-90 border-circle mb-3"
              style={{ width: "49px", height: "49px" }}>
              <i className="pi pi-money-bill text-xl text-white"></i>
            </span>
            <div className="text-2xl font-medium text-black-alpha-90 mb-2">
              {toplamBagisSayisi}
            </div>
            <span className="text-black-alpha-90 font-medium">
              Toplam Bağış Sayısı
            </span>
          </div>
        </div>
        <div className="col-12 md:col-6 lg:col-6 p-3">
          <div
            className="p-3 text-center bg-white-alpha-90 shadow-3"
            style={{ borderRadius: "12px" }}>
            <span
              className="inline-flex justify-content-center align-items-center bg-black-alpha-90 border-circle mb-3"
              style={{ width: "49px", height: "49px" }}>
              <i className="pi pi-turkish-lira text-xl text-white"></i>
            </span>
            <div className="text-2xl font-medium text-black-alpha-90 mb-2">
              {toplamBagisTutari}₺
            </div>
            <span className="text-black-alpha-90 font-medium">
              Toplam Bağış Tutarı
            </span>
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={bagislar}
          reorderableRows
          header={dataTableHeader}
          globalFilter={globalFilterValue}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={isLoading}>
          <Column
            header="Sayı"
            body={(rowData, options) => sayiHesapla(rowData, options.rowIndex)}
            sortable
          />
          <Column field="name" header="Ad Soyad" sortable />
          <Column field="email" header="E-Mail" sortable />
          <Column field="telefon" header="Telefon" sortable />
          <Column field="evadresi" header="Adres" sortable />
          <Column field="tutar" header="Tutar" sortable />
          <Column header="Dekont" body={templateDekont} />
          <Column
            field="created_at"
            header="Yapıldığı Tarihi"
            body={(e) => {
              return moment(e.created_at).format("DD.MM.YYYY");
            }}
            sortable
          />
          <Column header="İşlem" body={actionTemplate} />
        </DataTable>
      </div>
      <Dialog
        header={
          selectedBagislar && selectedBagislar.id
            ? "Kayıt Düzenle"
            : "Kayıt Ekle"
        }
        visible={visibleDialog}
        onHide={() => {
          setVisibleDialog(false);
          setSelectedBagislar(null);
        }}
        maximizable
        resizable={false}
        style={{ width: "75vw" }}>
        <div className="px-4 py-2 md:px-4 lg:px-4">
          <div className="grid">
            <div className="col-12">
              <div className="grid formgrid p-fluid">
                <div className="field mb-4 col-12 lg:col-4">
                  <label className="font-medium text-900">Ad Soyad</label>
                  <InputText
                    value={
                      selectedBagislar && selectedBagislar.name
                        ? selectedBagislar.name
                        : null
                    }
                    type="text"
                    readOnly
                  />
                </div>
                <div className="field mb-4 col-12 lg:col-4">
                  <label className="font-medium text-900">E-Mail</label>
                  <InputText
                    value={
                      selectedBagislar && selectedBagislar.email
                        ? selectedBagislar.email
                        : null
                    }
                    type="text"
                    readOnly
                  />
                </div>
                <div className="field mb-4 col-12 lg:col-4">
                  <label className="font-medium text-900">Telefon</label>
                  <InputText
                    value={
                      selectedBagislar && selectedBagislar.telefon
                        ? selectedBagislar.telefon
                        : null
                    }
                    type="text"
                    readOnly
                  />
                </div>
                <div className="field mb-4 col-12">
                  <label className="font-medium text-900">Adres</label>
                  <InputTextarea
                    value={
                      selectedBagislar && selectedBagislar.evadresi
                        ? selectedBagislar.evadresi
                        : null
                    }
                    type="text"
                    rows={4}
                    readOnly
                    autoResize
                  />
                </div>
                <div className="field mb-4 col-12">
                  <label htmlFor="avatar1" className="font-medium text-900">
                    Dekont
                  </label>
                  <br />
                  {selectedBagislar && selectedBagislar.dekontpdf ? (
                    <Fragment>
                      <div className="flex-none lg:flex">
                        <div className="field">
                          <a
                            className="p-button w-full justify-content-center"
                            icon="pi pi-check"
                            href={
                              selectedBagislar && selectedBagislar.dekontpdf
                                ? selectedBagislar.dekontpdf
                                : null
                            }
                            download
                            style={{
                              textDecoration: "none",
                            }}>
                            <i className="pi pi-download mr-1"></i> İndir
                          </a>
                        </div>
                        <div className="field">
                          <Button
                            label="Dosyayı Sil"
                            className="ml-0 lg:ml-2 w-full p-button justify-content-center"
                            icon="pi pi-trash"
                            severity="danger"
                            style={{
                              width: 175,
                            }}
                            onClick={() => {
                              setSelectedBagislar({
                                ...selectedBagislar,
                                dekontpdf: null,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Fragment>
                  ) : null}
                  <div className="mt-2">
                    <FileUpload
                      mode="advanced"
                      accept="*"
                      chooseOptions={{
                        className: "p-button-plain p-button-outlined",
                      }}
                      chooseLabel="Dosya Seç"
                      customUpload
                      onSelect={customBase64Uploader}
                      onRemove={() => {
                        setSelectedBagislar({
                          ...selectedBagislar,
                          dekontpdf: null,
                        });
                      }}
                      onClear={() => {
                        setSelectedBagislar({
                          ...selectedBagislar,
                          dekontpdf: null,
                        });
                      }}
                      cancelOptions={{ label: "Temizle" }}
                      uploadOptions={{ className: "hidden" }}
                      maxFileSize={1048576 * 10}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <Button
                    label="Kaydet"
                    className="w-auto mt-3"
                    onClick={() => {
                      submitBagislar(selectedBagislar);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default Bagislar;
