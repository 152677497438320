import React, { useState, useEffect, Fragment, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { getUsersFilters } from "../services/usersService";
import { getAllForm, deleteForm } from "../services/formService";
import { getAllBagislar } from "../services/bagislarService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Badge } from "primereact/badge";
import { FileUpload } from "primereact/fileupload";
import { InputSwitch } from "primereact/inputswitch";
import {
  getOdenmisAidatlar,
  putAidatlar,
  storeAidatlar,
  deleteAidatlar,
} from "../services/aidatlarService";

const Dashboard = () => {
  const [forms, setForms] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [toplamBorc, setToplamBorc] = useState(null);
  const [toplamBorcluKullanici, setToplamBorcluKullanici] = useState(null);
  const [toplamKullanici, setToplamKullanici] = useState(null);
  const [aktifKullanici, setAktifKullanici] = useState(null);
  const [toplamBagisTutari, setToplamBagisTutari] = useState(null);
  const [toplamBagisSayisi, setToplamBagisSayisi] = useState(null);
  const [toplamOdenmisAidatTutari, setToplamOdenmisAidatTutari] =
    useState(null);

  const [selectedAidatlar, setSelectedAidatlar] = useState(null);
  const [aidatlar, setAidatlar] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [filterCount, setFilterCount] = useState(null);

  const donemArray = () => {
    const yearArray = [];
    for (let index = 1992; index <= 2030; index++) {
      yearArray.push({ label: `${index}`, value: `${index}` });
    }
    return yearArray;
  };

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    setIsLoading(true);
    const fetchForms = async () => {
      const response = await getAllForm();
      if (response) {
        setForms(response);
        setIsLoading(false);
      }
    };
    const fetchUsers = async () => {
      const response = await getUsersFilters();
      if (response) {
        setToplamBorc(response.toplamborctutari);

        // Borçlu kullanıcıları filtreleme
        let borcluKullanicilar = response.users.filter((x) => x.borctutari > 0);
        setToplamBorcluKullanici(borcluKullanicilar.length);

        // Tüm kullanıcı sayısını ayarlama
        setToplamKullanici(response.count);

        // Aktif kullanıcıları filtreleme
        let aktifKullanicilar = response.users.filter((x) => x.status === 1);
        setAktifKullanici(aktifKullanicilar.length);
      }
    };
    const fetchBagislar = async () => {
      const response = await getAllBagislar();
      if (response) {
        setToplamBagisTutari(response.bagis_yapilan_tutar);
        setToplamBagisSayisi(response.bagis_sayisi);
      }
    };

    const fetchAidatlar = async () => {
      const response = await getOdenmisAidatlar();
      if (response) {
        setAidatlar(response.aidatlar);
        setFilterCount(response.odenmisAidatSayisi);
        setToplamOdenmisAidatTutari(response.odenen_borc);
        setIsLoading(false);
      }
    };

    fetchAidatlar();
    fetchForms();
    fetchUsers();
    fetchBagislar();
  };

  const customBase64Uploader = async (event) => {
    const file = event.files[0] ? event.files[0] : null;
    if (!file) {
      return;
    }
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedAidatlar({
          ...selectedAidatlar,
          dekontpdf: base64data,
        });
      }
    };
  };

  const submitAidatlar = async (aidatData) => {
    const response = await putAidatlar(aidatData.id, {
      ...aidatData,
    });
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Güncelleme Başarılı!",
        life: 1000,
      });
      refresh();
      setVisibleDialog(false);
      setSelectedAidatlar(null);
    }
  };

  const statusTemplate = (rowData) => {
    return (
      <div>
        <Badge
          value={rowData && rowData.status == 1 ? "Ödendi" : "Ödenmedi"}
          severity={rowData && rowData.status == 1 ? "success" : "danger"}
        />
      </div>
    );
  };

  const statusTemplateDekont = (rowData) => {
    return (
      <div>
        <Badge
          value={rowData && rowData.dekontpdf ? "Yüklendi" : "Yüklenmedi"}
          severity={rowData && rowData.dekontpdf ? "success" : "danger"}
        />
      </div>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="mr-1"
          severity="primary"
          onClick={() => {
            setSelectedAidatlar(rowData);
            setVisibleDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          onClick={() => deleteItem(rowData)}
        />
      </div>
    );
  };

  const readFieldUyeNo = (rowData) => {
    return <span>{rowData && rowData.user && rowData.user.uyeno}</span>;
  };

  const readFieldName = (rowData) => {
    return <span>{rowData && rowData.user && rowData.user.name}</span>;
  };

  const deleteItem = (rowData) => {
    const accept = async () => {
      const response = await deleteAidatlar(rowData.id);
      if (response) {
        toast.current.show({
          severity: "info",
          summary: "Silindi",
          detail: "Kayıt Başarıyla Kaldırıldı.",
          life: 3000,
        });
        refresh();
      }
    };

    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "İptal Edildi",
        detail: "Silme İşlemi İptal Edildi.",
        life: 3000,
      });
    };

    confirmDialog({
      group: "templating",
      header: "İşlemi Onayla",
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-times text-6xl text-red-500"></i>
          <span>İçerik sistemden kalıcı olarak silinecektir.</span>
        </div>
      ),
      accept,
      reject,
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      acceptClassName: "bg-red-500",
    });
  };

  const dataTableHeader = (
    <div className="flex align-items-center justify-content-between">
      <div>
        <span>{filterCount} kayıt bulundu.</span>
      </div>
      <div>
        <InputText
          type="search"
          placeholder="Arama yap..."
          size="30"
          onInput={(e) => {
            setGlobalFilterValue(e.target.value);
          }}
        />
      </div>
    </div>
  );

  const isFormValid = () => {
    return (
      selectedAidatlar &&
      selectedAidatlar.tur &&
      selectedAidatlar.donem &&
      selectedAidatlar.fiyat != null
    );
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog group="templating" />
      <div className="py-2">
        <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
          <h3 style={{ color: "rgb(108, 117, 125)" }}>Dashboard</h3>
        </div>
      </div>

      <div className="surface-ground px-4 py-5 md:px-6 lg:px-8">
        <div className="grid">
          <div className="col-12 md:col-6 lg:col-3 p-3">
            <div
              className="p-3 text-center bg-white-alpha-90 shadow-3"
              style={{ borderRadius: "12px" }}>
              <span
                className="inline-flex justify-content-center align-items-center bg-black-alpha-90 border-circle mb-3"
                style={{ width: "49px", height: "49px" }}>
                <i className="pi pi-users text-xl text-white"></i>
              </span>
              <div className="text-2xl font-medium text-black-alpha-90 mb-2">
                {toplamKullanici}
              </div>
              <span className="text-black-alpha-90 font-medium">
                Toplam Kullanıcı
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 p-3">
            <div
              className="p-3 text-center bg-white-alpha-90 shadow-3"
              style={{ borderRadius: "12px" }}>
              <span
                className="inline-flex justify-content-center align-items-center bg-black-alpha-90 border-circle mb-3"
                style={{ width: "49px", height: "49px" }}>
                <i className="pi pi-users text-xl text-white"></i>
              </span>
              <div className="text-2xl font-medium text-black-alpha-90 mb-2">
                {aktifKullanici}
              </div>
              <span className="text-black-alpha-90 font-medium">
                Aktif Kullanıcı
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 p-3">
            <div
              className="p-3 text-center bg-white-alpha-90 shadow-3"
              style={{ borderRadius: "12px" }}>
              <span
                className="inline-flex justify-content-center align-items-center bg-black-alpha-90 border-circle mb-3"
                style={{ width: "49px", height: "49px" }}>
                <i className="pi pi-users text-xl text-white"></i>
              </span>
              <div className="text-2xl font-medium text-black-alpha-90 mb-2">
                {toplamBorcluKullanici}
              </div>
              <span className="text-black-alpha-90 font-medium">
                Borçlu Kullanıcı
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 p-3">
            <div
              className="p-3 text-center bg-white-alpha-90 shadow-3"
              style={{ borderRadius: "12px" }}>
              <span
                className="inline-flex justify-content-center align-items-center bg-black-alpha-90 border-circle mb-3"
                style={{ width: "49px", height: "49px" }}>
                <i className="pi pi-turkish-lira text-xl text-white"></i>
              </span>
              <div className="text-2xl font-medium text-black-alpha-90 mb-2">
                {toplamBorc}₺
              </div>
              <span className="text-black-alpha-90 font-medium">
                Toplam Borç
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 p-3">
            <div
              className="p-3 text-center bg-white-alpha-90 shadow-3"
              style={{ borderRadius: "12px" }}>
              <span
                className="inline-flex justify-content-center align-items-center bg-black-alpha-90 border-circle mb-3"
                style={{ width: "49px", height: "49px" }}>
                <i className="pi pi-money-bill text-xl text-white"></i>
              </span>
              <div className="text-2xl font-medium text-black-alpha-90 mb-2">
                {toplamBagisSayisi}
              </div>
              <span className="text-black-alpha-90 font-medium">
                Toplam Bağış Sayısı
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 p-3">
            <div
              className="p-3 text-center bg-white-alpha-90 shadow-3"
              style={{ borderRadius: "12px" }}>
              <span
                className="inline-flex justify-content-center align-items-center bg-black-alpha-90 border-circle mb-3"
                style={{ width: "49px", height: "49px" }}>
                <i className="pi pi-turkish-lira text-xl text-white"></i>
              </span>
              <div className="text-2xl font-medium text-black-alpha-90 mb-2">
                {toplamBagisTutari}₺
              </div>
              <span className="text-black-alpha-90 font-medium">
                Toplam Bağış Tutarı
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 p-3">
            <div
              className="p-3 text-center bg-white-alpha-90 shadow-3"
              style={{ borderRadius: "12px" }}>
              <span
                className="inline-flex justify-content-center align-items-center bg-black-alpha-90 border-circle mb-3"
                style={{ width: "49px", height: "49px" }}>
                <i className="pi pi-users text-xl text-white"></i>
              </span>
              <div className="text-2xl font-medium text-black-alpha-90 mb-2">
                {filterCount}
              </div>
              <span className="text-black-alpha-90 font-medium">
                Ödenmiş Aidat Sayısı
              </span>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-3 p-3">
            <div
              className="p-3 text-center bg-white-alpha-90 shadow-3"
              style={{ borderRadius: "12px" }}>
              <span
                className="inline-flex justify-content-center align-items-center bg-black-alpha-90 border-circle mb-3"
                style={{ width: "49px", height: "49px" }}>
                <i className="pi pi-turkish-lira text-xl text-white"></i>
              </span>
              <div className="text-2xl font-medium text-black-alpha-90 mb-2">
                {toplamOdenmisAidatTutari}₺
              </div>
              <span className="text-black-alpha-90 font-medium">
                Toplam Ödenmiş Aidat Tutarı
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="py-2">
        <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
          <h3 style={{ color: "rgb(108, 117, 125)" }}>Ödenmiş Aidatlar</h3>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={aidatlar}
          header={dataTableHeader}
          globalFilter={globalFilterValue}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={isLoading}>
          <Column
            field="uyeno"
            header="Üye No"
            sortable
            body={readFieldUyeNo}
          />
          <Column
            field="name"
            header="Ad Soyad"
            sortable
            body={readFieldName}
          />
          <Column field="donem" header="Dönem" sortable />
          <Column field="tur" header="Aidat Türü" sortable />
          <Column field="fiyat" header="Ücret" sortable />
          <Column field="status" header="Ödenme Durumu" body={statusTemplate} />
          <Column header="Dekont" body={statusTemplateDekont} />
          <Column header="İşlem" body={actionTemplate} />
        </DataTable>
      </div>
      <Dialog
        header={
          selectedAidatlar && selectedAidatlar.id
            ? "Kayıt Düzenle"
            : "Kayıt Ekle"
        }
        visible={visibleDialog}
        onHide={() => {
          setVisibleDialog(false);
          setSelectedAidatlar(null);
        }}
        maximizable
        resizable={false}
        style={{ width: "75vw" }}>
        <div className="px-4 py-2 md:px-4 lg:px-4">
          <div className="grid">
            <div className="col-12">
              <div className="grid formgrid p-fluid">
                <div className="field mb-4 col-12 lg:col-4">
                  <label className="font-medium text-900">Aidat Türü</label>
                  <Dropdown
                    className="w-full"
                    value={
                      selectedAidatlar && selectedAidatlar.tur
                        ? selectedAidatlar.tur
                        : ""
                    }
                    options={[
                      { label: "Giriş Aidatı", value: "Giriş Aidatı" },
                      { label: "Yıllık Aidat", value: "Yıllık Aidat" },
                    ]}
                    onChange={(e) =>
                      setSelectedAidatlar({
                        ...selectedAidatlar,
                        tur: e.value,
                      })
                    }
                    placeholder="Aidat Türü"
                  />
                </div>
                <div className="field mb-4 col-12 lg:col-4">
                  <label className="font-medium text-900">Dönem</label>
                  <Dropdown
                    className="w-full"
                    value={
                      selectedAidatlar && selectedAidatlar.donem
                        ? selectedAidatlar.donem
                        : ""
                    }
                    options={donemArray()}
                    onChange={(e) =>
                      setSelectedAidatlar({
                        ...selectedAidatlar,
                        donem: e.value,
                      })
                    }
                    placeholder="Dönem Yılı"
                    filter
                  />
                </div>
                <div className="field mb-4 col-12 lg:col-4">
                  <label className="font-medium text-900">Fiyat</label>
                  <InputNumber
                    value={
                      selectedAidatlar && selectedAidatlar.fiyat
                        ? selectedAidatlar.fiyat
                        : null
                    }
                    onValueChange={(e) => {
                      setSelectedAidatlar({
                        ...selectedAidatlar,
                        fiyat: e.value,
                      });
                    }}
                    placeholder="Fiyat"
                  />
                </div>
                <div className="field mb-4 col-12 lg:col-4">
                  <label className="font-medium text-900">Ödenme Durumu</label>
                  <br />
                  <InputSwitch
                    checked={
                      selectedAidatlar && selectedAidatlar.status == 1
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      setSelectedAidatlar({
                        ...selectedAidatlar,
                        status: e.value,
                      })
                    }
                  />
                </div>
                {selectedAidatlar && selectedAidatlar.status == 1 ? (
                  <div className="field mb-4 col-12 lg:col-4">
                    <label className="font-medium text-900">
                      Ödendiği Tarihi
                    </label>
                    <br />
                    <Calendar
                      value={
                        selectedAidatlar && selectedAidatlar.odendigi_tarih
                          ? new Date(selectedAidatlar.odendigi_tarih)
                          : new Date()
                      }
                      dateFormat="dd/mm/yy"
                      onChange={(e) => {
                        setSelectedAidatlar({
                          ...selectedAidatlar,
                          odendigi_tarih: e.value,
                        });
                      }}
                    />
                  </div>
                ) : null}

                {selectedAidatlar && selectedAidatlar.status == 1 ? (
                  <div className="field mb-4 col-12">
                    <label htmlFor="avatar1" className="font-medium text-900">
                      Dekont
                    </label>
                    <br />
                    {selectedAidatlar && selectedAidatlar.dekontpdf ? (
                      <Fragment>
                        <div className="flex-none lg:flex">
                          <div className="field">
                            <a
                              className="p-button w-full justify-content-center"
                              icon="pi pi-check"
                              href={
                                selectedAidatlar && selectedAidatlar.dekontpdf
                                  ? selectedAidatlar.dekontpdf
                                  : null
                              }
                              download
                              style={{
                                textDecoration: "none",
                              }}>
                              <i className="pi pi-download mr-1"></i> İndir
                            </a>
                          </div>
                          <div className="field">
                            <Button
                              label="Dosyayı Sil"
                              className="ml-0 lg:ml-2 w-full p-button justify-content-center"
                              icon="pi pi-trash"
                              severity="danger"
                              style={{
                                width: 175,
                              }}
                              onClick={() => {
                                setSelectedAidatlar({
                                  ...selectedAidatlar,
                                  dekontpdf: null,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </Fragment>
                    ) : null}
                    <div className="mt-2">
                      <FileUpload
                        mode="advanced"
                        accept="*"
                        chooseOptions={{
                          className: "p-button-plain p-button-outlined",
                        }}
                        chooseLabel="Dosya Seç"
                        customUpload
                        onSelect={customBase64Uploader}
                        onRemove={() => {
                          setSelectedAidatlar({
                            ...selectedAidatlar,
                            dekontpdf: null,
                          });
                        }}
                        onClear={() => {
                          setSelectedAidatlar({
                            ...selectedAidatlar,
                            dekontpdf: null,
                          });
                        }}
                        cancelOptions={{ label: "Temizle" }}
                        uploadOptions={{ className: "hidden" }}
                        maxFileSize={1048576 * 10}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="col-12">
                  <Button
                    label="Kaydet"
                    className="w-auto mt-3"
                    disabled={!isFormValid()}
                    onClick={() => {
                      submitAidatlar(selectedAidatlar);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default Dashboard;
