import axiosInstance from "./axiosInstance";

export const getAllUsers = async () => {
  try {
    const response = await axiosInstance.get("/users");
    return response.data;
  } catch (error) {
    console.error("error getting users all: ", error);
  }
};

export const getUsers = async (id) => {
  try {
    const response = await axiosInstance.get(`/users/${id}`);
    return response.data;
  } catch (error) {
    console.error(`error getting users: ${id}`, error);
  }
};

export const getUsersFilters = async (filterData) => {
  try {
    const response = await axiosInstance.post(
      `/users/filters/filter`,
      filterData
    );
    return response.data;
  } catch (error) {
    console.error(`error getting users: `, error);
  }
};

export const getUsersFiltersAll = async (filterData) => {
  try {
    const response = await axiosInstance.post(
      `/users/filters/filter/all`,
      filterData
    );
    return response.data;
  } catch (error) {
    console.error(`error getting users: `, error);
  }
};

export const storeUsers = async (usersData) => {
  try {
    const response = await axiosInstance.post("/users", usersData);
    return response;
  } catch (error) {
    console.error(`error getting users store: `, error);
  }
};

export const putUsers = async (id, usersData) => {
  try {
    const response = await axiosInstance.put(`/users/${id}`, usersData);
    return response;
  } catch (error) {
    console.error(`error getting users update: ${id}`, error);
  }
};

export const putUsersPassword = async (id, usersData) => {
  try {
    const response = await axiosInstance.put(
      `/users/${id}/password`,
      usersData
    );
    return response;
  } catch (error) {
    console.error(`error getting users update: ${id}`, error);
  }
};

export const deleteUsers = async (id) => {
  try {
    const response = await axiosInstance.delete(`/users/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting users delete: ${id}`, error);
  }
};
