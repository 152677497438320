import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layout/layout";
import Dashboard from "./pages/dashboard";
import "./assets/styles/main.css";
import Login from "./pages/auth/login";
import Settings from "./pages/settings/settings";
import Users from "./pages/users/users";
import UsersEdit from "./pages/users/users-edit";
import PrivateRouteManager from "./utils/managers/privateRouteManager";
import Blog from "./pages/blog/blog";
import Form from "./pages/form/form";
import BlogYazarlar from "./pages/blogYazarlar/blogYazarlar";
import DernektenHaber from "./pages/dernektenHaber/dernektenHaber";
import Hakkimizda from "./pages/hakkimizda/hakkimizda";
import KanKampanyalari from "./pages/kanKampanyalari/kanKampanyalari";
import Kunye from "./pages/kunye/kunye";
import Kurumsal from "./pages/kurumsal/kurumsal";
import SabitSayfalar from "./pages/sabitSayfalar/sabitSayfalar";
import Slider from "./pages/slider/slider";
import Sponsorlarimiz from "./pages/sponsorlarimiz/sponsorlarimiz";
import Tarihce from "./pages/tarihce/tarihce";
import UyelikFormu from "./pages/uyelikFormu/uyelikFormu";
import YkVeKuUyeleri from "./pages/ykvekuuyeleri/ykvekuuyeleri";
import Yoneticiler from "./pages/yoneticiler/yoneticiler";
import HeaderMenu from "./pages/menu/headermenu";
import FooterMenu from "./pages/menu/footermenu";
import Bagislar from "./pages/bagislar/bagislar";
import Bildirimler from "./pages/bildirimler/bildirimler";
import Sponsorluklar from "./pages/sponsorluklar/sponsorluklar";

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route
              path="/"
              element={
                <PrivateRouteManager>
                  <Dashboard />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/blog"
              element={
                <PrivateRouteManager>
                  <Blog />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/blog-yazarlar"
              element={
                <PrivateRouteManager>
                  <BlogYazarlar />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/dernekten-haberler"
              element={
                <PrivateRouteManager>
                  <DernektenHaber />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/kan-kampanyalari"
              element={
                <PrivateRouteManager>
                  <KanKampanyalari />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/iletisim-formlari"
              element={
                <PrivateRouteManager>
                  <Form />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/hakkimizda"
              element={
                <PrivateRouteManager>
                  <Hakkimizda />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/kunye"
              element={
                <PrivateRouteManager>
                  <Kunye />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/sabit-sayfalar"
              element={
                <PrivateRouteManager>
                  <SabitSayfalar />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/kurumsal"
              element={
                <PrivateRouteManager>
                  <Kurumsal />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/site-bilgileri"
              element={
                <PrivateRouteManager>
                  <Settings />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/slider"
              element={
                <PrivateRouteManager>
                  <Slider />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/sponsorlarimiz"
              element={
                <PrivateRouteManager>
                  <Sponsorlarimiz />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/sponsorluklar"
              element={
                <PrivateRouteManager>
                  <Sponsorluklar />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/tarihce"
              element={
                <PrivateRouteManager>
                  <Tarihce />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/uyelik-formlari"
              element={
                <PrivateRouteManager>
                  <UyelikFormu />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/yk-ve-ku-uyeleri"
              element={
                <PrivateRouteManager>
                  <YkVeKuUyeleri />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/kullanicilar"
              element={
                <PrivateRouteManager>
                  <Users />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/header-menu"
              element={
                <PrivateRouteManager>
                  <HeaderMenu />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/footer-menu"
              element={
                <PrivateRouteManager>
                  <FooterMenu />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/bildirimler"
              element={
                <PrivateRouteManager>
                  <Bildirimler />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/bagislar"
              element={
                <PrivateRouteManager>
                  <Bagislar />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/yoneticiler"
              element={
                <PrivateRouteManager>
                  <Yoneticiler />
                </PrivateRouteManager>
              }
            />
            <Route
              path="/kullanicilar/:id"
              element={
                <PrivateRouteManager>
                  <UsersEdit />
                </PrivateRouteManager>
              }
            />
            {/* <Route path="/users/1" element={<UsersEdit />} /> */}
          </Route>
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
