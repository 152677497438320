import axiosInstance from "./axiosInstance";

export const getAllSlider = async () => {
  try {
    const response = await axiosInstance.get("/slider");
    return response.data;
  } catch (error) {
    console.error("error getting slider all: ", error);
  }
};

export const getSlider = async (id) => {
  try {
    const response = await axiosInstance.get(`/slider/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting slider: ${id}`, error);
  }
};

export const storeSlider = async (sliderData) => {
  try {
    const response = await axiosInstance.post("/slider", sliderData);
    return response;
  } catch (error) {
    console.error(`error getting slider store: `, error);
  }
};

export const putSlider = async (id, sliderData) => {
  try {
    const response = await axiosInstance.put(`/slider/${id}`, sliderData);
    return response;
  } catch (error) {
    console.error(`error getting slider update: ${id}`, error);
  }
};

export const putRangeSlider = async (sliderData) => {
  try {
    const response = await axiosInstance.put(
      `/slider/range/update`,
      sliderData
    );
    return response;
  } catch (error) {
    console.error(`error getting slider range update:`, error);
  }
};

export const deleteSlider = async (id) => {
  try {
    const response = await axiosInstance.delete(`/slider/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting slider delete: ${id}`, error);
  }
};
