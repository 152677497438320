import axios from "axios";
import appConfig from "../config/appConfig";
import tokenManager from "../utils/managers/tokenManager";

const baseUrl = appConfig.apiBaseUrl;
const token = localStorage.getItem("token");
const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: token ? `Bearer ${token}` : "",
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const updatedConfig = { ...config };
    const authToken = localStorage.getItem("token");
    if (authToken) {
      updatedConfig.headers.Authorization = `Bearer ${authToken}`;
    }
    return updatedConfig;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      tokenManager.clear(true);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
