import axiosInstance from "./axiosInstance";
import tokenManager from "../utils/managers/tokenManager";

export const getToken = async (email, password) => {
  try {
    const response = await axiosInstance.post("user/login", {
      email: email,
      password: password,
    });
    if (response) {
      tokenManager.addToken(response.data.token);
    }
    return response.data;
  } catch (error) {
    console.error("error getting user token: ", error);
  }
};

export const logout = async () => {
  tokenManager.clear(true);
};
