import React, { useState, useEffect, Fragment, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  getAllYoneticiler,
  putYoneticiler,
  storeYoneticiler,
  deleteYoneticiler,
} from "../../services/yoneticilerService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { Image } from "primereact/image";
import { Password } from "primereact/password";
import { Badge } from "primereact/badge";

const Yoneticiler = () => {
  const [yoneticiler, setYoneticiler] = useState(null);
  const [selectedYoneticiler, setSelectedYoneticiler] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordField = () => {
    setShowPassword(!showPassword);
    setSelectedYoneticiler({
      ...selectedYoneticiler,
      password: "",
    });
  };

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    setIsLoading(true);
    const fetchYoneticiler = async () => {
      const response = await getAllYoneticiler();
      if (response) {
        setYoneticiler(response);
        setIsLoading(false);
      }
    };

    fetchYoneticiler();
  };

  const submitYoneticiler = async (yoneticilerlerData) => {
    if (!yoneticilerlerData.id) {
      const response = await storeYoneticiler(yoneticilerlerData);
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Kayıt Eklendi!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setYoneticiler(null);
      }
    } else {
      const response = await putYoneticiler(
        yoneticilerlerData.id,
        yoneticilerlerData
      );
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Güncelleme Başarılı!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setYoneticiler(null);
      }
    }
  };

  const statusTemplate = (rowData) => {
    return (
      <div>
        <InputSwitch
          checked={rowData.status == 1 ? true : false}
          onChange={(e) => {
            if (rowData) {
              const updatedYoneticiler = {
                ...rowData,
                status: e.value,
              };
              submitYoneticiler(updatedYoneticiler);
            }
          }}
        />
      </div>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="mr-1"
          severity="primary"
          onClick={() => {
            // setSelectedYoneticiler(rowData);

            setSelectedYoneticiler({
              ...rowData,
              password: null,
            });
            setVisibleDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          onClick={() => deleteItem(rowData)}
        />
      </div>
    );
  };

  const deleteItem = (rowData) => {
    const accept = async () => {
      const response = await deleteYoneticiler(rowData.id);
      if (response) {
        toast.current.show({
          severity: "info",
          summary: "Silindi",
          detail: "Kayıt Başarıyla Kaldırıldı.",
          life: 3000,
        });
        refresh();
      }
    };

    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "İptal Edildi",
        detail: "Silme İşlemi İptal Edildi.",
        life: 3000,
      });
    };

    confirmDialog({
      group: "templating",
      header: "İşlemi Onayla",
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-times text-6xl text-red-500"></i>
          <span>İçerik sistemden kalıcı olarak silinecektir.</span>
        </div>
      ),
      accept,
      reject,
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      acceptClassName: "bg-red-500",
    });
  };

  const dataTableHeader = (
    <Fragment>
      <div className="flex align-items-center justify-content-between">
        <div>
          <span>{yoneticiler ? yoneticiler.length : 0} kayıt bulundu.</span>
        </div>
        <div>
          <InputText
            type="search"
            placeholder="Arama yap..."
            size="30"
            onInput={(e) => {
              setGlobalFilterValue(e.target.value);
            }}
          />
        </div>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog group="templating" />
      <div className="py-2">
        <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
          <h3 style={{ color: "rgb(108, 117, 125)" }}>Yöneticilerimiz</h3>
          <div className="mt-3 md:mt-0">
            <Button
              label="Yeni Kayıt Ekle"
              className="p-button-outlined mr-2"
              icon="pi pi-plus"
              onClick={() => {
                setVisibleDialog(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={yoneticiler}
          header={dataTableHeader}
          globalFilter={globalFilterValue}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={isLoading}>
          <Column field="name" header="Ad Soyad" sortable />
          <Column field="email" header="E-Mail" sortable />
          <Column field="status" header="Aktiflik" body={statusTemplate} />
          <Column header="İşlem" body={actionTemplate} />
        </DataTable>
      </div>
      <Dialog
        header={
          selectedYoneticiler && selectedYoneticiler.id
            ? "Kayıt Düzenle"
            : "Kayıt Ekle"
        }
        visible={visibleDialog}
        onHide={() => {
          setVisibleDialog(false);
          setSelectedYoneticiler(null);
        }}
        maximizable
        resizable={false}
        style={{ width: "75vw" }}>
        <div className="px-4 py-2 md:px-4 lg:px-4">
          <div className="grid">
            <div className="col-12">
              <div className="grid formgrid p-fluid">
                <div className="field mb-4 col-12 lg:col-4">
                  <label className="font-medium text-900">Ad Soyad</label>
                  <InputText
                    value={
                      selectedYoneticiler && selectedYoneticiler.name
                        ? selectedYoneticiler.name
                        : ""
                    }
                    onChange={(e) => {
                      setSelectedYoneticiler({
                        ...selectedYoneticiler,
                        name: e.target.value,
                      });
                    }}
                    type="text"
                  />
                </div>
                <div className="field mb-4 col-12 lg:col-4">
                  <label className="font-medium text-900">E-Mail</label>
                  <InputText
                    value={
                      selectedYoneticiler && selectedYoneticiler.email
                        ? selectedYoneticiler.email
                        : ""
                    }
                    onChange={(e) => {
                      setSelectedYoneticiler({
                        ...selectedYoneticiler,
                        email: e.target.value,
                      });
                    }}
                    type="text"
                  />
                </div>
                <div className="field mb-4 col-12 lg:col-4">
                  <label className="font-medium text-900">
                    Şifre
                    {showPassword && (
                      <Badge
                        value="Şifreyi Gizle"
                        onClick={togglePasswordField}
                        className="ml-2"
                      />
                    )}
                  </label>
                  {showPassword ? (
                    <Fragment>
                      <Password
                        onChange={(e) => {
                          setSelectedYoneticiler({
                            ...selectedYoneticiler,
                            password: e.target.value,
                          });
                        }}
                        toggleMask
                      />
                    </Fragment>
                  ) : (
                    <Button
                      label="Şifreyi Göster"
                      onClick={togglePasswordField}
                    />
                  )}
                </div>
                <div className="col-12">
                  <Button
                    label="Kaydet"
                    className="w-auto mt-3"
                    onClick={() => {
                      submitYoneticiler(selectedYoneticiler);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default Yoneticiler;
