import React, { Fragment, useEffect, useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { getUsers, putUsers } from "../../services/usersService";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";

const HesapBilgileri = () => {
  const toast = useRef(null);
  const [user, setUser] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchUser = async () => {
      const response = await getUsers(id);
      if (response) {
        setUser(response);
      }
    };

    fetchUser();
  }, []);

  const odemeSekliData = [
    { name: "Online Ödeme", code: "Online Ödeme" },
    { name: "Havale / EFT", code: "Havale / EFT" },
  ];

  const customBase64Uploader = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setUser({
          ...user,
          resim: base64data,
        });
      }
    };
  };

  const submitUser = async (userData) => {
    try {
      const response = await putUsers(userData.id, userData);
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Güncelleme Başarılı!",
          life: 1000,
        });
      }
    } catch (error) {
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: error.response.data.error,
        life: 3000,
      });
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />

      <div className="surface-section px-4 py-2 md:px-4 lg:px-4">
        <div className="grid">
          <div className="col-12">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Resim
                </label>
                <br />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={customBase64Uploader}
                    onRemove={() => {
                      setUser({
                        ...user,
                        resim: null,
                      });
                    }}
                    onClear={() => {
                      setUser({
                        ...user,
                        resim: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12 md:col-4">
                <label className="font-medium text-900">Karar No</label>
                <InputText
                  value={user && user.kararno ? user.kararno : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      kararno: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-4">
                <label className="font-medium text-900">Üye No</label>
                <InputText
                  value={user && user.uyeno ? user.uyeno : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      uyeno: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-4">
                <label className="font-medium text-900">Kongre No</label>
                <InputText
                  value={user && user.kongreno ? user.kongreno : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      kongreno: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">Ad Soyad</label>
                <InputText
                  value={user && user.name ? user.name : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      name: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">Telefon</label>
                <InputText
                  value={user && user.telefon ? user.telefon : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      telefon: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">E-Mail</label>
                <InputText
                  value={user && user.email ? user.email : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      email: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">T.C. Kimlik No</label>
                <InputText
                  value={user && user.tckimlikno ? user.tckimlikno : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      tckimlikno: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">Baba Adı</label>
                <InputText
                  value={user && user.babaadi ? user.babaadi : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      babaadi: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">Doğum Yeri</label>
                <InputText
                  value={user && user.dogumyeri ? user.dogumyeri : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      dogumyeri: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">Doğum Tarihi</label>
                <InputText
                  value={user && user.dogumtarihi ? user.dogumtarihi : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      dogumtarihi: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">Meslek</label>
                <InputText
                  value={user && user.meslek ? user.meslek : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      meslek: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">
                  Son Mezun Okul / Bölüm
                </label>
                <InputTextarea
                  value={
                    user && user.sonmezunokulbolum
                      ? user.sonmezunokulbolum
                      : null
                  }
                  onChange={(e) => {
                    setUser({
                      ...user,
                      sonmezunokulbolum: e.target.value,
                    });
                  }}
                  type="text"
                  rows={3}
                  autoResize
                />
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">Ev Adresi</label>
                <InputTextarea
                  value={user && user.evadresi ? user.evadresi : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      evadresi: e.target.value,
                    });
                  }}
                  type="text"
                  rows={3}
                  autoResize
                />
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">İş Adresi</label>
                <InputTextarea
                  value={user && user.isadresi ? user.isadresi : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      isadresi: e.target.value,
                    });
                  }}
                  type="text"
                  rows={3}
                  autoResize
                />
              </div>
              <div className="field mb-4 col-12 md:col-4">
                <label className="font-medium text-900">
                  Sık Kullandığı Sosyal Medya
                </label>
                <InputText
                  value={
                    user && user.sosyalmedyalink ? user.sosyalmedyalink : null
                  }
                  onChange={(e) => {
                    setUser({
                      ...user,
                      sosyalmedyalink: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-4">
                <label className="font-medium text-900">Referans Kişi</label>
                <InputText
                  value={user && user.referanskisi ? user.referanskisi : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      referanskisi: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-4">
                <label htmlFor="odemeSekli" className="font-medium text-900">
                  Ödeme Şekli Tercihi
                </label>
                <Dropdown
                  inputId="odemeSekli"
                  options={odemeSekliData}
                  optionValue="code"
                  value={user && user.odemesekli ? user.odemesekli : null}
                  onChange={(e) => setUser({ ...user, odemesekli: e.value })}
                  optionLabel="name"
                  showClear
                  placeholder="Ödeme Şekli Tercihi"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">IBAN Numarası</label>
                <InputText
                  value={user && user.ibanno ? user.ibanno : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      ibanno: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">IBAN Ad Soyad</label>
                <InputText
                  value={user && user.ibanadsoyad ? user.ibanadsoyad : null}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      ibanadsoyad: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="col-12">
                <Button
                  label="Kaydet"
                  onClick={() => {
                    submitUser(user);
                  }}
                  className="w-auto mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HesapBilgileri;
