import React, { useState, useEffect, Fragment, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  getAllDernektenHaber,
  putDernektenHaber,
  storeDernektenHaber,
  putRangeDernektenHaber,
  deleteDernektenHaber,
} from "../../services/dernektenhaberService";
import {
  getAllGaleriResimByRelatedEntityParameters,
  deleteGaleriResim,
  putRangeGaleriResim,
  storeGaleriResim,
} from "../../services/galeriResimService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { Image } from "primereact/image";
import moment from "moment";

const DernektenHaber = () => {
  const [dernektenHaber, setDernektenHaber] = useState(null);
  const [selectedDernektenHaber, setSelectedDernektenHaber] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [visibleAlbumDialog, setVisibleAlbumDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [albumImages, setAlbumImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    setIsLoading(true);
    const fetchDernektenHaber = async () => {
      const response = await getAllDernektenHaber();
      if (response) {
        setDernektenHaber(response);
        setIsLoading(false);
      }
    };

    fetchDernektenHaber();
  };

  const fetchAlbumImages = async (haberId) => {
    const response = await getAllGaleriResimByRelatedEntityParameters({
      icerik_id: haberId,
      album_adi: "haberler",
    });
    if (response) {
      setAlbumImages(response);
    }
  };

  const customBase64Uploader = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedDernektenHaber({
          ...selectedDernektenHaber,
          resim: base64data,
        });
      }
    };
  };

  const submitDernektenHaberler = async (dernektenHaberlerData) => {
    if (!dernektenHaberlerData.id) {
      const response = await storeDernektenHaber(dernektenHaberlerData);
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Kayıt Eklendi!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setSelectedDernektenHaber(null);
      }
    } else {
      const response = await putDernektenHaber(
        dernektenHaberlerData.id,
        dernektenHaberlerData
      );
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Güncelleme Başarılı!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setSelectedDernektenHaber(null);
      }
    }
  };

  const statusTemplate = (rowData) => {
    return (
      <div>
        <InputSwitch
          checked={rowData.status == 1 ? true : false}
          onChange={(e) => {
            if (rowData) {
              const updatedDernektenHaberler = {
                ...rowData,
                status: e.value,
              };
              submitDernektenHaberler(updatedDernektenHaberler);
            }
          }}
        />
      </div>
    );
  };

  const onRowReOrder = async (event) => {
    const reordered = event.value;
    const updatedDernektenHaber = reordered.map((category, index) => {
      return { ...category, orders: index + 1 };
    });

    setDernektenHaber(updatedDernektenHaber);

    try {
      await putRangeDernektenHaber(updatedDernektenHaber);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Sıralama başarılı.",
        life: 3000,
      });
    } catch (error) {
      console.error("Reorder failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Sıralama başarısız.",
        life: 3000,
      });
    }
  };

  const onAlbumRowReOrder = async (event) => {
    const reordered = event.value;
    const updatedAlbumImages = reordered.map((image, index) => {
      return { ...image, orders: index + 1 };
    });

    setAlbumImages(updatedAlbumImages);

    try {
      await putRangeGaleriResim(updatedAlbumImages);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Albüm sıralaması başarılı.",
        life: 3000,
      });
    } catch (error) {
      console.error("Reorder failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Albüm sıralaması başarısız.",
        life: 3000,
      });
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="mr-1"
          severity="primary"
          onClick={() => {
            setSelectedDernektenHaber(rowData);
            setVisibleDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          onClick={() => deleteItem(rowData)}
        />
        <Button
          icon="pi pi-images"
          label="Albüm Resimleri"
          severity="success"
          className="ml-1"
          onClick={() => {
            setSelectedDernektenHaber(rowData);
            fetchAlbumImages(rowData.id);
            setVisibleAlbumDialog(true);
          }}
        />
      </div>
    );
  };

  const deleteItem = (rowData) => {
    const accept = async () => {
      const response = await deleteDernektenHaber(rowData.id);
      if (response) {
        toast.current.show({
          severity: "info",
          summary: "Silindi",
          detail: "Kayıt Başarıyla Kaldırıldı.",
          life: 3000,
        });
        refresh();
      }
    };

    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "İptal Edildi",
        detail: "Silme İşlemi İptal Edildi.",
        life: 3000,
      });
    };

    confirmDialog({
      group: "templating",
      header: "İşlemi Onayla",
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-times text-6xl text-red-500"></i>
          <span>İçerik sistemden kalıcı olarak silinecektir.</span>
        </div>
      ),
      accept,
      reject,
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      acceptClassName: "bg-red-500",
    });
  };

  const dataTableHeader = (
    <Fragment>
      <div className="flex align-items-center justify-content-between">
        <div>
          <span>
            {dernektenHaber ? dernektenHaber.length : 0} kayıt bulundu.
          </span>
        </div>
        <div>
          <InputText
            type="search"
            placeholder="Arama yap..."
            size="30"
            onInput={(e) => {
              setGlobalFilterValue(e.target.value);
            }}
          />
        </div>
      </div>
    </Fragment>
  );

  const customBase64AlbumUploader = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedImage({ resim: base64data });
      }
    };
  };

  const submitAlbumImage = async () => {
    let data = {
      ...selectedImage,
      album_adi: "haberler",
      icerik_id: selectedDernektenHaber.id,
    };

    const response = await storeGaleriResim(data);
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Resim Eklendi!",
        life: 1000,
      });
      fetchAlbumImages(selectedDernektenHaber.id);
      setSelectedImage(null);
    }
  };

  const deleteAlbumImage = async (imageId) => {
    const response = await deleteGaleriResim(imageId);
    if (response) {
      toast.current.show({
        severity: "info",
        summary: "Silindi",
        detail: "Resim Başarıyla Kaldırıldı.",
        life: 3000,
      });
      fetchAlbumImages(selectedDernektenHaber.id);
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog group="templating" />
      <div className="py-2">
        <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
          <h3 style={{ color: "rgb(108, 117, 125)" }}>Haberler</h3>
          <div className="mt-3 md:mt-0">
            <Button
              label="Yeni Kayıt Ekle"
              className="p-button-outlined mr-2"
              icon="pi pi-plus"
              onClick={() => {
                setVisibleDialog(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={dernektenHaber}
          reorderableRows
          onRowReorder={(e) => onRowReOrder(e)}
          header={dataTableHeader}
          globalFilter={globalFilterValue}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={isLoading}
        >
          <Column rowReorder style={{ width: "3em", fontSize: 5 }} />
          <Column field="orders" header="Sıra No" sortable />
          <Column
            field="resim"
            header="Resim"
            body={(row) => {
              return row && row.resim ? (
                <Image height="100" src={row.resim} preview />
              ) : null;
            }}
          />
          <Column field="baslik" header="Başlık" sortable />
          <Column
            field="created_at"
            header="Oluşturulma Tarihi"
            body={(e) => {
              return moment(e.created_at).format("DD.MM.YYYY");
            }}
            sortable
          />
          <Column
            field="updated_at"
            header="Son Güncellenme Tarihi"
            body={(e) => {
              return moment(e.updated_at).format("DD.MM.YYYY");
            }}
            sortable
          />
          <Column field="status" header="Aktiflik" body={statusTemplate} />
          <Column header="İşlem" body={actionTemplate} />
        </DataTable>
      </div>
      <Dialog
        header={
          selectedDernektenHaber && selectedDernektenHaber.id
            ? "Kayıt Düzenle"
            : "Kayıt Ekle"
        }
        visible={visibleDialog}
        onHide={() => {
          setVisibleDialog(false);
          setSelectedDernektenHaber(null);
        }}
        maximizable
        resizable={false}
        style={{ width: "75vw" }}
      >
        <div className="px-4 py-2 md:px-4 lg:px-4">
          <div className="grid">
            <div className="col-12">
              <div className="grid formgrid p-fluid">
                <div className="field mb-4 col-12">
                  <label htmlFor="avatar1" className="font-medium text-900">
                    Resim (Tavsiye Edilen Boyut: 1080 x 1080)
                  </label>
                  <br />
                  <Image
                    src={
                      selectedDernektenHaber && selectedDernektenHaber.resim
                        ? selectedDernektenHaber.resim
                        : null
                    }
                    className="mr-4"
                    width="250"
                    preview
                  />
                  <div className="mt-2">
                    <FileUpload
                      mode="advanced"
                      accept="image/*"
                      chooseOptions={{
                        className: "p-button-plain p-button-outlined",
                      }}
                      chooseLabel="Resim Seç"
                      customUpload
                      onSelect={customBase64Uploader}
                      onRemove={() => {
                        setSelectedDernektenHaber({
                          ...selectedDernektenHaber,
                          resim: null,
                        });
                      }}
                      onClear={() => {
                        setSelectedDernektenHaber({
                          ...selectedDernektenHaber,
                          resim: null,
                        });
                      }}
                      cancelOptions={{ label: "Temizle" }}
                      uploadOptions={{ className: "hidden" }}
                    />
                  </div>
                </div>
                <div className="field mb-4 col-12">
                  <label htmlFor="baslik" className="font-medium text-900">
                    Başlık
                  </label>
                  <InputText
                    value={
                      selectedDernektenHaber && selectedDernektenHaber.baslik
                        ? selectedDernektenHaber.baslik
                        : null
                    }
                    onChange={(e) => {
                      setSelectedDernektenHaber({
                        ...selectedDernektenHaber,
                        baslik: e.target.value,
                      });
                    }}
                    type="text"
                  />
                </div>
                <div className="field mb-4 col-12">
                  <label htmlFor="seo" className="font-medium text-900">
                    SEO
                  </label>
                  <InputTextarea
                    value={
                      selectedDernektenHaber && selectedDernektenHaber.seo
                        ? selectedDernektenHaber.seo
                        : null
                    }
                    onChange={(e) => {
                      setSelectedDernektenHaber({
                        ...selectedDernektenHaber,
                        seo: e.target.value,
                      });
                    }}
                    type="text"
                    rows={4}
                    autoResize={false}
                    style={{ resize: "vertical", overflow: "auto" }}
                  />
                </div>
                <div className="field mb-4 col-12">
                  <label htmlFor="yazi" className="font-medium text-900">
                    Yazı
                  </label>
                  <Editor
                    autoResize={false}
                    style={{
                      width: "100%",
                      minHeight: 150,
                      resize: "vertical",
                      overflow: "auto",
                    }}
                    value={
                      selectedDernektenHaber && selectedDernektenHaber.yazi
                        ? selectedDernektenHaber.yazi
                        : null
                    }
                    onTextChange={(e) => {
                      setSelectedDernektenHaber({
                        ...selectedDernektenHaber,
                        yazi: e.htmlValue,
                      });
                    }}
                  />
                </div>
                <div className="col-12">
                  <Button
                    label="Kaydet"
                    className="w-auto mt-3"
                    onClick={() => {
                      submitDernektenHaberler(selectedDernektenHaber);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Albüm Resimleri"
        visible={visibleAlbumDialog}
        onHide={() => {
          setAlbumImages(null);
          setSelectedImage(null);
          setVisibleAlbumDialog(false);
        }}
        maximizable
        resizable={false}
        style={{ width: "75vw" }}
      >
        <div className="px-4 py-2 md:px-4 lg:px-4">
          <div className="grid formgrid p-fluid">
            <div className="grid mb-4 col-12">
              <div className="col-12">
                <FileUpload
                  mode="advanced"
                  accept="image/*"
                  chooseOptions={{
                    className: "p-button-plain p-button-outlined",
                  }}
                  chooseLabel="Resim Seç"
                  customUpload
                  onSelect={customBase64AlbumUploader}
                  onRemove={() => {
                    setSelectedImage(null);
                  }}
                  onClear={() => {
                    setSelectedImage(null);
                  }}
                  cancelOptions={{ label: "Temizle" }}
                  uploadOptions={{ className: "hidden" }}
                />
              </div>
              <div className="col-12 mt-3">
                <div className="field">
                  {selectedImage && (
                    <Button
                      label="Resmi Ekle ve Kaydet"
                      className="w-auto flex"
                      onClick={submitAlbumImage}
                    />
                  )}
                </div>
              </div>
            </div>
            {albumImages && albumImages.length > 0 ? (
              <div className="col-12">
                <DataTable
                  value={albumImages}
                  reorderableRows
                  onRowReorder={onAlbumRowReOrder}
                >
                  <Column rowReorder style={{ width: "3em" }} />
                  <Column field="orders" header="Sıra No" sortable />
                  <Column
                    field="resim"
                    header="Resim"
                    body={(row) => (
                      <Image height="100" src={row.resim} preview />
                    )}
                  />
                  <Column
                    header="Sil"
                    body={(row) => (
                      <Button
                        icon="pi pi-trash"
                        severity="danger"
                        onClick={() => deleteAlbumImage(row.id)}
                      />
                    )}
                  />
                </DataTable>
              </div>
            ) : null}
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default DernektenHaber;
