import React, { Fragment, useEffect, useRef, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import {
  getSitebilgileri,
  putSitebilgileri,
} from "../../services/sitebilgileriService";
import { Toast } from "primereact/toast";
import { Image } from "primereact/image";

const GenelBilgiler = () => {
  const toast = useRef(null);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await getSitebilgileri();
      if (response) {
        setSettings(response[0]);
      }
    };

    fetchSettings();
  }, []);

  const customBase64Uploader = async (event, type) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        if (type == "logo") {
          setSettings({
            ...settings,
            logo: base64data,
          });
        }

        if (type == "logo2") {
          setSettings({
            ...settings,
            logo2: base64data,
          });
        }

        if (type == "favicon") {
          setSettings({
            ...settings,
            favicon: base64data,
          });
        }

        if (type == "breadcrumb") {
          setSettings({
            ...settings,
            breadcrumb: base64data,
          });
        }

        if (type == "besiktasveataturkbreadcrumb") {
          setSettings({
            ...settings,
            besiktasveataturkbreadcrumb: base64data,
          });
        }

        if (type == "iletisimbreadcrumb") {
          setSettings({
            ...settings,
            iletisimbreadcrumb: base64data,
          });
        }

        if (type == "haberlerbreadcrumb") {
          setSettings({
            ...settings,
            haberlerbreadcrumb: base64data,
          });
        }

        if (type == "sponsorlukbreadcrumb") {
          setSettings({
            ...settings,
            sponsorlukbreadcrumb: base64data,
          });
        }

        if (type == "hakkimizdabreadcrumb") {
          setSettings({
            ...settings,
            hakkimizdabreadcrumb: base64data,
          });
        }
      }
    };
  };

  const submitSettings = async (settingsData) => {
    const response = await putSitebilgileri(settingsData.id, settingsData);
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Güncelleme Başarılı!",
        life: 1000,
      });
    }
  };
  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="surface-section px-4 py-2 md:px-4 lg:px-4">
        <div className="grid">
          <div className="col-12">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Logo
                </label>
                <br />
                <Image
                  src={settings && settings.logo ? settings.logo : null}
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) => customBase64Uploader(event, "logo")}
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        logo: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        logo: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Logo(Alternatif)
                </label>
                <br />
                <Image
                  src={settings && settings.logo2 ? settings.logo2 : null}
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) => customBase64Uploader(event, "logo2")}
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        logo2: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        logo2: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Favicon
                </label>
                <br />
                <Image
                  src={settings && settings.favicon ? settings.favicon : null}
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) => customBase64Uploader(event, "favicon")}
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        favicon: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        favicon: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Genel Breadcrumb
                </label>
                <br />
                <Image
                  src={
                    settings && settings.breadcrumb ? settings.breadcrumb : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "breadcrumb")
                    }
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        breadcrumb: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        breadcrumb: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>

              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Beşiktaş ve Atatürk Breadcrumb
                </label>
                <br />
                <Image
                  src={
                    settings && settings.besiktasveataturkbreadcrumb
                      ? settings.besiktasveataturkbreadcrumb
                      : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "besiktasveataturkbreadcrumb")
                    }
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        besiktasveataturkbreadcrumb: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        besiktasveataturkbreadcrumb: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  İletişim Breadcrumb
                </label>
                <br />
                <Image
                  src={
                    settings && settings.iletisimbreadcrumb
                      ? settings.iletisimbreadcrumb
                      : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "iletisimbreadcrumb")
                    }
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        iletisimbreadcrumb: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        iletisimbreadcrumb: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Haberler Breadcrumb
                </label>
                <br />
                <Image
                  src={
                    settings && settings.haberlerbreadcrumb
                      ? settings.haberlerbreadcrumb
                      : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "haberlerbreadcrumb")
                    }
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        haberlerbreadcrumb: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        haberlerbreadcrumb: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Sponsorluk Breadcrumb
                </label>
                <br />
                <Image
                  src={
                    settings && settings.sponsorlukbreadcrumb
                      ? settings.sponsorlukbreadcrumb
                      : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "sponsorlukbreadcrumb")
                    }
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        sponsorlukbreadcrumb: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        sponsorlukbreadcrumb: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="avatar1" className="font-medium text-900">
                  Hakkımızda Breadcrumb
                </label>
                <br />
                <Image
                  src={
                    settings && settings.hakkimizdabreadcrumb
                      ? settings.hakkimizdabreadcrumb
                      : null
                  }
                  className="mr-4"
                  width="250"
                  preview
                />
                <div className="mt-2">
                  <FileUpload
                    mode="advanced"
                    accept="image/*"
                    chooseOptions={{
                      className: "p-button-plain p-button-outlined",
                    }}
                    chooseLabel="Resim Seç"
                    customUpload
                    onSelect={(event) =>
                      customBase64Uploader(event, "hakkimizdabreadcrumb")
                    }
                    onRemove={() => {
                      setSettings({
                        ...settings,
                        hakkimizdabreadcrumb: null,
                      });
                    }}
                    onClear={() => {
                      setSettings({
                        ...settings,
                        hakkimizdabreadcrumb: null,
                      });
                    }}
                    cancelOptions={{ label: "Temizle" }}
                    uploadOptions={{ className: "hidden" }}
                  />
                </div>
              </div>
              <div className="field mb-4 col-12 md:col-12">
                <label htmlFor="siteAdi" className="font-medium text-900">
                  Site Adı
                </label>
                <InputText
                  value={settings && settings.siteAdi ? settings.siteAdi : null}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      siteAdi: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <label htmlFor="siteSeo" className="font-medium text-900">
                  Site SEO
                </label>
                <InputTextarea
                  value={settings && settings.siteSeo ? settings.siteSeo : null}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      siteSeo: e.target.value,
                    });
                  }}
                  type="text"
                  rows={4}
                  autoResize
                />
              </div>
              <div className="col-12">
                <Button
                  label="Kaydet"
                  onClick={() => {
                    submitSettings(settings);
                  }}
                  className="w-auto mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GenelBilgiler;
