import axiosInstance from "./axiosInstance";

export const getAllKankampanyalari = async () => {
  try {
    const response = await axiosInstance.get("/kan-kampanyalari");
    return response.data;
  } catch (error) {
    console.error("error getting kan kampanyaları all: ", error);
  }
};

export const getKankampanyalari = async (id) => {
  try {
    const response = await axiosInstance.get(`/kan-kampanyalari/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting kan kampanyaları: ${id}`, error);
  }
};

export const storeKankampanyalari = async (kankampanyalariData) => {
  try {
    const response = await axiosInstance.post(
      "/kan-kampanyalari",
      kankampanyalariData
    );
    return response;
  } catch (error) {
    console.error(`error getting kan kampanyaları store:`, error);
  }
};

export const putKankampanyalari = async (id, kankampanyalariData) => {
  try {
    const response = await axiosInstance.put(
      `/kan-kampanyalari/${id}`,
      kankampanyalariData
    );
    return response;
  } catch (error) {
    console.error(`error getting kan kampanyaları update: ${id}`, error);
  }
};

export const putRangeKankampanyalari = async (kankampanyalariData) => {
  try {
    const response = await axiosInstance.put(
      `/kan-kampanyalari/range/update`,
      kankampanyalariData
    );
    return response;
  } catch (error) {
    console.error(`error getting kan kampanyaları range update:`, error);
  }
};

export const deleteKankampanyalari = async (id) => {
  try {
    const response = await axiosInstance.delete(`/kan-kampanyalari/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting kan kampanyaları delete: ${id}`, error);
  }
};
