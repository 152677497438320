import React, { useState, useEffect, Fragment, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  getAllHeaderMenu,
  putHeaderMenu,
  storeHeaderMenu,
  putRangeHeaderMenu,
  deleteHeaderMenu,
} from "../../services/headermenuService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";

const HeaderMenu = () => {
  const [headerMenu, setHeaderMenu] = useState(null);
  const [selectedHeaderMenu, setSelectedHeaderMenu] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubMenu, setIsSubMenu] = useState(false);
  const [isRelationalAdd, setIsRelationalAdd] = useState(false);
  const toast = useRef(null);
  const [expandedRows, setExpandedRows] = useState(null);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    setIsLoading(true);
    const fetchHeaderMenu = async () => {
      const response = await getAllHeaderMenu();
      if (response) {
        setHeaderMenu(response);
        setIsLoading(false);
      }
    };

    fetchHeaderMenu();
  };

  const submitHeaderMenu = async (headerMenulerData) => {
    if (isRelationalAdd) {
      let data = {
        ...headerMenulerData,
        ust_menu_id: selectedHeaderMenu.id,
        kategori: "header",
      };
      const response = await storeHeaderMenu(data);
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "İlişkili Kayıt Eklendi!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setHeaderMenu(null);
        setIsRelationalAdd(false);
      }
    } else {
      if (!headerMenulerData.id) {
        let data = { ...headerMenulerData, kategori: "header" };
        const response = await storeHeaderMenu(data);
        if (response) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Kayıt Eklendi!",
            life: 1000,
          });
          refresh();
          setVisibleDialog(false);
          setHeaderMenu(null);
        }
      } else {
        let data = { ...headerMenulerData, kategori: "header" };
        const response = await putHeaderMenu(data.id, data);
        if (response) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Güncelleme Başarılı!",
            life: 1000,
          });
          refresh();
          setVisibleDialog(false);
          setHeaderMenu(null);
        }
      }
    }
  };

  const statusTemplate = (rowData) => {
    return (
      <div>
        <InputSwitch
          checked={rowData.status == 1 ? true : false}
          onChange={(e) => {
            if (rowData) {
              const updatedHeaderMenu = {
                ...rowData,
                status: e.value,
              };
              submitHeaderMenu(updatedHeaderMenu);
            }
          }}
        />
      </div>
    );
  };

  const onRowReOrder = async (event, parentId = null) => {
    const reordered = event.value;
    const updatedHeaderMenu = reordered.map((menu, index) => {
      return { ...menu, orders: index + 1 };
    });

    if (parentId) {
      const parentIndex = headerMenu.findIndex((menu) => menu.id === parentId);
      const updatedParent = {
        ...headerMenu[parentIndex],
        children: updatedHeaderMenu,
      };
      const newHeaderMenu = [...headerMenu];
      newHeaderMenu[parentIndex] = updatedParent;
      setHeaderMenu(newHeaderMenu);
    } else {
      setHeaderMenu(updatedHeaderMenu);
    }

    try {
      await putRangeHeaderMenu(updatedHeaderMenu);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Sıralama başarılı.",
        life: 3000,
      });
    } catch (error) {
      console.error("Reorder failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Sıralama başarısız.",
        life: 3000,
      });
    }
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable
          reorderableRows
          onRowReorder={(e) => onRowReOrder(e, data.id)}
          value={data.children}
        >
          <Column rowReorder style={{ width: "3em", fontSize: 5 }} />
          <Column field="orders" header="Sıra" sortable></Column>
          <Column field="baslik" header="Başlık" sortable></Column>
          <Column field="link" header="Link" sortable></Column>
          <Column field="status" header="Aktiflik" body={statusTemplate} />
          <Column header="İşlem" body={(e) => actionTemplate(e, true)} />
        </DataTable>
      </div>
    );
  };

  const allowExpansion = (rowData) => {
    return rowData.children && rowData.children.length > 0;
  };

  const actionTemplate = (rowData, isSubMenu) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="mr-1"
          severity="primary"
          onClick={() => {
            setIsSubMenu(isSubMenu);
            setSelectedHeaderMenu(rowData);
            setVisibleDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          onClick={() => deleteItem(rowData)}
        />
        {!isSubMenu ? (
          <Button
            className="ml-1"
            icon="pi pi-plus"
            label="Alt Menü Ekle"
            disabled={rowData.alt_menu_var_mi === 0 ? true : false}
            severity="success"
            onClick={() => {
              setIsSubMenu(false);
              setIsRelationalAdd(true);
              setSelectedHeaderMenu(rowData);
              setVisibleDialog(true);
            }}
          />
        ) : null}
      </div>
    );
  };

  const deleteItem = (rowData) => {
    const accept = async () => {
      const response = await deleteHeaderMenu(rowData.id);
      if (response) {
        toast.current.show({
          severity: "info",
          summary: "Silindi",
          detail: "Kayıt Başarıyla Kaldırıldı.",
          life: 3000,
        });
        refresh();
      }
    };

    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "İptal Edildi",
        detail: "Silme İşlemi İptal Edildi.",
        life: 3000,
      });
    };

    confirmDialog({
      group: "templating",
      header: "İşlemi Onayla",
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-times text-6xl text-red-500"></i>
          <span>İçerik sistemden kalıcı olarak silinecektir.</span>
        </div>
      ),
      accept,
      reject,
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      acceptClassName: "bg-red-500",
    });
  };

  const dataTableHeader = (
    <div style={{ textAlign: "right" }}>
      <InputText
        type="search"
        placeholder="Arama yap..."
        size="30"
        onInput={(e) => {
          setGlobalFilterValue(e.target.value);
        }}
      />
    </div>
  );

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog group="templating" />
      <div className="py-2">
        <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
          <h3 style={{ color: "rgb(108, 117, 125)" }}>Header Menü</h3>
          <div className="mt-3 md:mt-0">
            <Button
              label="Yeni Kayıt Ekle"
              className="p-button-outlined mr-2"
              icon="pi pi-plus"
              onClick={() => {
                setVisibleDialog(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={headerMenu}
          reorderableRows
          onRowReorder={(e) => onRowReOrder(e)}
          expandedRows={expandedRows}
          onRowToggle={async (e) => {
            setExpandedRows(e.data);
          }}
          rowExpansionTemplate={rowExpansionTemplate}
          header={dataTableHeader}
          globalFilter={globalFilterValue}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={isLoading}
        >
          <Column expander={allowExpansion} style={{ width: "5rem" }} />
          <Column rowReorder style={{ width: "3em", fontSize: 5 }} />
          <Column field="orders" header="Sıra No" sortable />
          <Column field="baslik" header="Başlık" sortable />
          <Column field="link" header="Link" />
          <Column field="status" header="Aktiflik" body={statusTemplate} />
          <Column header="İşlem" body={(e) => actionTemplate(e, false)} />
        </DataTable>
      </div>
      <Dialog
        header={
          selectedHeaderMenu && selectedHeaderMenu.id && !isRelationalAdd
            ? "Kayıt Düzenle"
            : "Kayıt Ekle"
        }
        visible={visibleDialog}
        onHide={() => {
          setVisibleDialog(false);
          setSelectedHeaderMenu(null);
          setIsRelationalAdd(false);
        }}
        maximizable
        resizable={false}
        style={{ width: "75vw" }}
      >
        <div className="px-4 py-2 md:px-4 lg:px-4">
          <div className="grid">
            <div className="col-12">
              <div className="grid formgrid p-fluid">
                <div className="field mb-4 col-12">
                  <label className="font-medium text-900">Başlık</label>
                  <InputText
                    value={
                      !isRelationalAdd &&
                      selectedHeaderMenu &&
                      selectedHeaderMenu.baslik
                        ? selectedHeaderMenu.baslik
                        : null
                    }
                    onChange={(e) => {
                      setSelectedHeaderMenu({
                        ...selectedHeaderMenu,
                        baslik: e.target.value,
                      });
                    }}
                    type="text"
                  />
                </div>
                <div className="field mb-4 col-12">
                  <label className="font-medium text-900">Link</label>
                  <InputText
                    value={
                      !isRelationalAdd &&
                      selectedHeaderMenu &&
                      selectedHeaderMenu.link
                        ? selectedHeaderMenu.link
                        : null
                    }
                    onChange={(e) => {
                      setSelectedHeaderMenu({
                        ...selectedHeaderMenu,
                        link: e.target.value,
                      });
                    }}
                    type="text"
                  />
                </div>
                {!isSubMenu && !isRelationalAdd ? (
                  <div className="field mb-4 col-12">
                    <label className="flex font-medium text-900">
                      Alt Menü Var mı?
                    </label>
                    <InputSwitch
                      className="flex"
                      checked={
                        selectedHeaderMenu &&
                        selectedHeaderMenu.alt_menu_var_mi === 1
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        setSelectedHeaderMenu({
                          ...selectedHeaderMenu,
                          alt_menu_var_mi: e.value === true ? 1 : 0,
                        })
                      }
                    />
                  </div>
                ) : null}

                <div className="col-12">
                  <Button
                    label="Kaydet"
                    className="w-auto mt-3"
                    onClick={() => {
                      submitHeaderMenu(selectedHeaderMenu);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default HeaderMenu;
