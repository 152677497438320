import axiosInstance from "./axiosInstance";

export const getAllHeaderMenu = async () => {
  try {
    const response = await axiosInstance.get("/header-menu");
    return response.data;
  } catch (error) {
    console.error("error getting header menü all: ", error);
  }
};

export const getHeaderMenu = async (id) => {
  try {
    const response = await axiosInstance.get(`/header-menu/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting header menü: ${id}`, error);
  }
};

export const storeHeaderMenu = async (kankampanyalariData) => {
  try {
    const response = await axiosInstance.post(
      "/header-menu",
      kankampanyalariData
    );
    return response;
  } catch (error) {
    console.error(`error getting header menü store:`, error);
  }
};

export const putHeaderMenu = async (id, kankampanyalariData) => {
  try {
    const response = await axiosInstance.put(
      `/header-menu/${id}`,
      kankampanyalariData
    );
    return response;
  } catch (error) {
    console.error(`error getting header menü update: ${id}`, error);
  }
};

export const putRangeHeaderMenu = async (kankampanyalariData) => {
  try {
    const response = await axiosInstance.put(
      `/header-menu/range/update`,
      kankampanyalariData
    );
    return response;
  } catch (error) {
    console.error(`error getting header menü range update:`, error);
  }
};

export const deleteHeaderMenu = async (id) => {
  try {
    const response = await axiosInstance.delete(`/header-menu/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting header menü delete: ${id}`, error);
  }
};
