import axiosInstance from "./axiosInstance";

export const getAllBagislar = async () => {
  try {
    const response = await axiosInstance.get("/bagislar");
    return response.data;
  } catch (error) {
    console.error("error getting bagislar all: ", error);
  }
};

export const getBagislar = async (id) => {
  try {
    const response = await axiosInstance.get(`/bagislar/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting bagislar: ${id}`, error);
  }
};

export const storeBagislar = async (bagislarData) => {
  try {
    const response = await axiosInstance.post("/bagislar", bagislarData);
    return response;
  } catch (error) {
    console.error(`error getting bagislar store: `, error);
  }
};

export const putBagislar = async (id, bagislarData) => {
  try {
    const response = await axiosInstance.put(`/bagislar/${id}`, bagislarData);
    return response;
  } catch (error) {
    console.error(`error getting bagislar update: ${id}`, error);
  }
};

export const deleteBagislar = async (id) => {
  try {
    const response = await axiosInstance.delete(`/bagislar/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting bagislar delete: ${id}`, error);
  }
};
