import axiosInstance from "./axiosInstance";

export const getAllDernektenHaber = async () => {
  try {
    const response = await axiosInstance.get("/dernekten-haber");
    return response.data;
  } catch (error) {
    console.error("error getting dernekten haber all: ", error);
  }
};

export const getDernektenHaber = async (id) => {
  try {
    const response = await axiosInstance.get(`/dernekten-haber/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting dernekten haber: ${id}`, error);
  }
};

export const storeDernektenHaber = async (dernektenhaberData) => {
  try {
    const response = await axiosInstance.post(
      "/dernekten-haber",
      dernektenhaberData
    );
    return response;
  } catch (error) {
    console.error(`error getting dernekten haber store: `, error);
  }
};

export const putDernektenHaber = async (id, dernektenhaberData) => {
  try {
    const response = await axiosInstance.put(
      `/dernekten-haber/${id}`,
      dernektenhaberData
    );
    return response;
  } catch (error) {
    console.error(`error getting dernekten haber update: ${id}`, error);
  }
};

export const putRangeDernektenHaber = async (dernektenhaberData) => {
  try {
    const response = await axiosInstance.put(
      `/dernekten-haber/range/update`,
      dernektenhaberData
    );
    return response;
  } catch (error) {
    console.error(`error getting dernekten haber range update:`, error);
  }
};

export const deleteDernektenHaber = async (id) => {
  try {
    const response = await axiosInstance.delete(`/dernekten-haber/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting dernekten haber delete: ${id}`, error);
  }
};
