import axiosInstance from "./axiosInstance";

export const getAllBlog = async () => {
  try {
    const response = await axiosInstance.get("/blog");
    return response.data;
  } catch (error) {
    console.error("error getting blog all: ", error);
  }
};

export const getBlog = async (id) => {
  try {
    const response = await axiosInstance.get(`/blog/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting blog: ${id}`, error);
  }
};

export const storeBlog = async (blogData) => {
  try {
    const response = await axiosInstance.post("/blog", blogData);
    return response;
  } catch (error) {
    console.error(`error getting blog store: `, error);
  }
};

export const putBlog = async (id, blogData) => {
  try {
    const response = await axiosInstance.put(`/blog/${id}`, blogData);
    return response;
  } catch (error) {
    console.error(`error getting blog update: ${id}`, error);
  }
};

export const putRangeBlog = async (blogData) => {
  try {
    const response = await axiosInstance.put(`/blog/range/update`, blogData);
    return response;
  } catch (error) {
    console.error(`error getting blog range update:`, error);
  }
};

export const deleteBlog = async (id) => {
  try {
    const response = await axiosInstance.delete(`/blog/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting blog delete: ${id}`, error);
  }
};
