import axiosInstance from "./axiosInstance";

export const getAidatlar = async (id) => {
  try {
    const response = await axiosInstance.get(`/aidatlar/users/${id}`);
    return response.data;
  } catch (error) {
    console.error(`error getting aidatlar: ${id}`, error);
  }
};

export const getOdenmisAidatlar = async () => {
  try {
    const response = await axiosInstance.get(
      `/aidatlar/users/aidatlar/odenmis`
    );
    return response.data;
  } catch (error) {
    console.error(`error getting aidatlar: `, error);
  }
};

export const storeAidatlar = async (aidatlarData) => {
  try {
    const response = await axiosInstance.post("/aidatlar", aidatlarData);
    return response;
  } catch (error) {
    console.error(`error getting aidatlar store: `, error);
  }
};

export const putAidatlar = async (id, aidatlarData) => {
  try {
    const response = await axiosInstance.put(`/aidatlar/${id}`, aidatlarData);
    return response;
  } catch (error) {
    console.error(`error getting aidatlar update: ${id}`, error);
  }
};

export const deleteAidatlar = async (id) => {
  try {
    const response = await axiosInstance.delete(`/aidatlar/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting aidatlar delete: ${id}`, error);
  }
};
