import axiosInstance from "./axiosInstance";

export const getHakkimizda = async () => {
  try {
    const response = await axiosInstance.get("/hakkimizda");
    return response.data;
  } catch (error) {
    console.error("error getting hakkımızda all: ", error);
  }
};

export const putHakkimizda = async (id, hakkimizdaData) => {
  try {
    const response = await axiosInstance.put(
      `/hakkimizda/${id}`,
      hakkimizdaData
    );
    return response;
  } catch (error) {
    console.error(`error getting hakkımızda update: ${id}`, error);
  }
};
