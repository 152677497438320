import axiosInstance from "./axiosInstance";

export const getAllBlogYazarlar = async () => {
  try {
    const response = await axiosInstance.get("/blog-yazarlar");
    return response.data;
  } catch (error) {
    console.error("error getting blog yazarlar all: ", error);
  }
};

export const getBlogYazarlar = async (id) => {
  try {
    const response = await axiosInstance.get(`/blog-yazarlar/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting blog yazarlar: ${id}`, error);
  }
};

export const storeBlogYazarlar = async (blogyazarlarData) => {
  try {
    const response = await axiosInstance.post(
      "/blog-yazarlar",
      blogyazarlarData
    );
    return response;
  } catch (error) {
    console.error(`error getting blog yazarlar store: `, error);
  }
};

export const putBlogYazarlar = async (id, blogyazarlarData) => {
  try {
    const response = await axiosInstance.put(
      `/blog-yazarlar/${id}`,
      blogyazarlarData
    );
    return response;
  } catch (error) {
    console.error(`error getting blog yazarlar update: ${id}`, error);
  }
};

export const deleteBlogYazarlar = async (id) => {
  try {
    const response = await axiosInstance.delete(`/blog-yazarlar/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting blog yazarlar delete: ${id}`, error);
  }
};
