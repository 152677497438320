import axiosInstance from "./axiosInstance";

export const getAllFooterMenu = async () => {
  try {
    const response = await axiosInstance.get("/footer-menu");
    return response.data;
  } catch (error) {
    console.error("error getting footer menü all: ", error);
  }
};

export const getFooterMenu = async (id) => {
  try {
    const response = await axiosInstance.get(`/footer-menu/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting footer menü: ${id}`, error);
  }
};

export const storeFooterMenu = async (kankampanyalariData) => {
  try {
    const response = await axiosInstance.post(
      "/footer-menu",
      kankampanyalariData
    );
    return response;
  } catch (error) {
    console.error(`error getting footer menü store:`, error);
  }
};

export const putFooterMenu = async (id, kankampanyalariData) => {
  try {
    const response = await axiosInstance.put(
      `/footer-menu/${id}`,
      kankampanyalariData
    );
    return response;
  } catch (error) {
    console.error(`error getting footer menü update: ${id}`, error);
  }
};

export const putRangeFooterMenu = async (kankampanyalariData) => {
  try {
    const response = await axiosInstance.put(
      `/footer-menu/range/update`,
      kankampanyalariData
    );
    return response;
  } catch (error) {
    console.error(`error getting footer menü range update:`, error);
  }
};

export const deleteFooterMenu = async (id) => {
  try {
    const response = await axiosInstance.delete(`/footer-menu/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting footer menü delete: ${id}`, error);
  }
};
