import React, { Fragment } from "react";

const Footer = () => {
  return (
    <Fragment>
      <div className="footer py-2 lg:py-2 md:px-6 lg:px-8 flex flex-column lg:flex-row justify-content-center">
        <ul className="list-none p-0 mb-0 flex flex-column flex-order-1">
          <li className="mr-4 lg:mt-0 text-center">
            <a tabIndex="0" className="cursor-pointer">
              Copyright Tüm Hakları Saklıdır.
            </a>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default Footer;
