import axiosInstance from "./axiosInstance";

export const getAllYkvekuuyeleri = async () => {
  try {
    const response = await axiosInstance.get("/yk-ve-ku-uyeleri");
    return response.data;
  } catch (error) {
    console.error("error getting yk ve ku üyeleri all: ", error);
  }
};

export const getYkvekuuyeleri = async (id) => {
  try {
    const response = await axiosInstance.get(`/yk-ve-ku-uyeleri/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting yk ve ku üyeleri: ${id}`, error);
  }
};

export const storeYkvekuuyeleri = async (ykvekuuyeleriData) => {
  try {
    const response = await axiosInstance.post(
      "/yk-ve-ku-uyeleri",
      ykvekuuyeleriData
    );
    return response;
  } catch (error) {
    console.error(`error getting yk ve ku üyeleri store: `, error);
  }
};

export const putYkvekuuyeleri = async (id, ykvekuuyeleriData) => {
  try {
    const response = await axiosInstance.put(
      `/yk-ve-ku-uyeleri/${id}`,
      ykvekuuyeleriData
    );
    return response;
  } catch (error) {
    console.error(`error getting yk ve ku üyeleri update: ${id}`, error);
  }
};

export const putRangeYkvekuuyeleri = async (ykvekuuyeleriData) => {
  try {
    const response = await axiosInstance.put(
      `/yk-ve-ku-uyeleri/range/update`,
      ykvekuuyeleriData
    );
    return response;
  } catch (error) {
    console.error(`error getting yk ve ku üyeleri range update:`, error);
  }
};

export const deleteYkvekuuyeleri = async (id) => {
  try {
    const response = await axiosInstance.delete(`/yk-ve-ku-uyeleri/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting yk ve ku üyeleri delete: ${id}`, error);
  }
};
