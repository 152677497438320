import axiosInstance from "./axiosInstance";

export const getAllSponsorlarimiz = async () => {
  try {
    const response = await axiosInstance.get("/sponsorlarimiz");
    return response.data;
  } catch (error) {
    console.error("error getting sponsorlarımız all: ", error);
  }
};

export const getSponsorlarimiz = async (id) => {
  try {
    const response = await axiosInstance.get(`/sponsorlarimiz/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting sponsorlarımız: ${id}`, error);
  }
};

export const storeSponsorlarimiz = async (sponsorlarimizData) => {
  try {
    const response = await axiosInstance.post(
      "/sponsorlarimiz",
      sponsorlarimizData
    );
    return response;
  } catch (error) {
    console.error(`error getting sponsorlarımız store: `, error);
  }
};

export const putSponsorlarimiz = async (id, sponsorlarimizData) => {
  try {
    const response = await axiosInstance.put(
      `/sponsorlarimiz/${id}`,
      sponsorlarimizData
    );
    return response;
  } catch (error) {
    console.error(`error getting sponsorlarımız update: ${id}`, error);
  }
};

export const putRangeSponsorlarimiz = async (sponsorlarimizData) => {
  try {
    const response = await axiosInstance.put(
      `/sponsorlarimiz/range/update`,
      sponsorlarimizData
    );
    return response;
  } catch (error) {
    console.error(`error getting sponsorlarımız range update:`, error);
  }
};

export const deleteSponsorlarimiz = async (id) => {
  try {
    const response = await axiosInstance.delete(`/sponsorlarimiz/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting sponsorlarımız delete: ${id}`, error);
  }
};
