import React, { Fragment, useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import { Ripple } from "primereact/ripple";
import HesapBilgileri from "../../components/users/HesapBilgileri";
import SifreDegistir from "../../components/users/SifreDegistir";
import Aidatlar from "../../components/users/Aidatlar";
import Belgeler from "../../components/users/Belgeler";
import { getUsers, putUsers } from "../../services/usersService";
import { useParams } from "react-router-dom";
import { Image } from "primereact/image";

const UsersEdit = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [user, setUser] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchUser = async () => {
      const response = await getUsers(id);
      if (response) {
        setUser(response);
      }
    };

    fetchUser();
  }, []);
  return (
    <Fragment>
      <h3 style={{ color: "rgb(108, 117, 125)" }}>Kullanıcı Bilgileri</h3>
      <div className="surface-section px-4 pt-4">
        <div className="grid">
          <div className="col-12 md:col-4">
            <Image
              src={user && user.resim ? user.resim : null}
              height="100"
              preview
              className="mb-3"
            />
            <div className="text-xl text-900 font-medium mb-1">
              {user && user.name ? user.name : null}
            </div>
            <div className="text-sm text-900 font-medium">
              {user && user.email ? user.email : null}
            </div>
          </div>
        </div>
      </div>
      <ul className="surface-card p-2 md:p-4 lg:p-4 m-0 list-none flex overflow-x-auto select-none">
        <li className="mr-2">
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 0,
                "text-700": activeTab !== 0,
              }
            )}
            onClick={() => setActiveTab(0)}>
            <span className="font-medium">Hesap Bilgileri</span>
            <Ripple />
          </a>
        </li>
        <li className="mr-2">
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 1,
                "text-700": activeTab !== 1,
              }
            )}
            onClick={() => setActiveTab(1)}>
            <span className="font-medium">Şifre İşlemleri</span>
            <Ripple />
          </a>
        </li>
        <li className="mr-2">
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 2,
                "text-700": activeTab !== 2,
              }
            )}
            onClick={() => setActiveTab(2)}>
            <span className="font-medium">Belgeler</span>
            <Ripple />
          </a>
        </li>
        <li className="mr-2">
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 3,
                "text-700": activeTab !== 3,
              }
            )}
            onClick={() => setActiveTab(3)}>
            <span className="font-medium">Aidatlar</span>
            <Ripple />
          </a>
        </li>
      </ul>
      {activeTab === 0 ? (
        <HesapBilgileri />
      ) : activeTab === 1 ? (
        <SifreDegistir />
      ) : activeTab === 2 ? (
        <Belgeler />
      ) : activeTab === 3 ? (
        <Aidatlar />
      ) : null}
    </Fragment>
  );
};

export default UsersEdit;
