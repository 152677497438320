import React, { useState, useEffect, Fragment, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { getAllForm, deleteForm } from "../../services/formService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";

const Form = () => {
  const [forms, setForms] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    setIsLoading(true);
    const fetchForms = async () => {
      const response = await getAllForm();
      if (response) {
        setForms(response);
        setIsLoading(false);
      }
    };

    fetchForms();
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-eye"
          className="mr-1"
          severity="primary"
          onClick={() => {
            setSelectedForm(rowData);
            setVisibleDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          onClick={() => deleteItem(rowData)}
        />
      </div>
    );
  };

  const deleteItem = (rowData) => {
    const accept = async () => {
      const response = await deleteForm(rowData.id);
      if (response) {
        toast.current.show({
          severity: "info",
          summary: "Silindi",
          detail: "Kayıt Başarıyla Kaldırıldı.",
          life: 3000,
        });
        refresh();
      }
    };

    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "İptal Edildi",
        detail: "Silme İşlemi İptal Edildi.",
        life: 3000,
      });
    };

    confirmDialog({
      group: "templating",
      header: "İşlemi Onayla",
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-times text-6xl text-red-500"></i>
          <span>İçerik sistemden kalıcı olarak silinecektir.</span>
        </div>
      ),
      accept,
      reject,
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      acceptClassName: "bg-red-500",
    });
  };

  const dataTableHeader = (
    <Fragment>
      <div className="flex align-items-center justify-content-between">
        <div>
          <span>{forms ? forms.length : 0} kayıt bulundu.</span>
        </div>
        <div>
          <InputText
            type="search"
            placeholder="Arama yap..."
            size="30"
            onInput={(e) => {
              setGlobalFilterValue(e.target.value);
            }}
          />
        </div>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog group="templating" />
      <div className="py-2">
        <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
          <h3 style={{ color: "rgb(108, 117, 125)" }}>İletişim Formları</h3>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={forms}
          reorderableRows
          header={dataTableHeader}
          globalFilter={globalFilterValue}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={isLoading}>
          <Column field="adsoyad" header="Ad Soyad" sortable />
          <Column field="email" header="E-Mail" sortable />
          <Column field="telefon" header="Telefon" sortable />
          <Column header="İşlem" body={actionTemplate} />
        </DataTable>
      </div>
      <Dialog
        header={"Kayıt Görüntüle"}
        visible={visibleDialog}
        onHide={() => {
          setVisibleDialog(false);
          setSelectedForm(null);
        }}
        maximizable
        resizable={false}
        style={{ width: "75vw" }}>
        <div className="px-4 py-2 md:px-4 lg:px-4">
          <div className="grid">
            <div className="col-12">
              <div className="grid formgrid p-fluid">
                <div className="field mb-4 col-12">
                  <label className="font-medium text-900">Ad Soyad</label>
                  <InputText
                    value={
                      selectedForm && selectedForm.adsoyad
                        ? selectedForm.adsoyad
                        : null
                    }
                    type="text"
                    readOnly
                  />
                </div>
                <div className="field mb-4 col-6">
                  <label className="font-medium text-900">E-Mail</label>
                  <InputText
                    value={
                      selectedForm && selectedForm.email
                        ? selectedForm.email
                        : null
                    }
                    type="text"
                    readOnly
                  />
                </div>
                <div className="field mb-4 col-6">
                  <label className="font-medium text-900">Telefon</label>
                  <InputText
                    value={
                      selectedForm && selectedForm.telefon
                        ? selectedForm.telefon
                        : null
                    }
                    type="text"
                    readOnly
                  />
                </div>
                <div className="field mb-4 col-12">
                  <label className="font-medium text-900">Açıklama</label>
                  <InputTextarea
                    value={
                      selectedForm && selectedForm.mesaj
                        ? selectedForm.mesaj
                        : null
                    }
                    type="text"
                    rows={4}
                    autoResize
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default Form;
