import axiosInstance from "./axiosInstance";

export const getAllGaleriResimByRelatedEntityParameters = async (data) => {
  try {
    const response = await axiosInstance.post("/icerik/galeri-resim", data);
    return response.data;
  } catch (error) {
    console.error("error getting galeri resim all: ", error);
  }
};

export const storeGaleriResim = async (galeriResimData) => {
  try {
    const response = await axiosInstance.post("/galeri-resim", galeriResimData);
    return response;
  } catch (error) {
    console.error(`error getting galeri resim store: `, error);
  }
};

export const putRangeGaleriResim = async (galeriResimData) => {
  try {
    const response = await axiosInstance.put(
      `/galeri-resim/range/update`,
      galeriResimData
    );
    return response;
  } catch (error) {
    console.error(`error getting galeri resim range update:`, error);
  }
};

export const deleteGaleriResim = async (id) => {
  try {
    const response = await axiosInstance.delete(`/galeri-resim/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting galeri resim delete: ${id}`, error);
  }
};
