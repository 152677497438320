import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { getToken } from "../../services/authService";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { getSitebilgileri } from "../../services/sitebilgileriService";

const Login = () => {
  const [logo, setLogo] = useState(null);
  const [email, setEmail] = useState("info@izmirbesiktaslilardernegi.org.tr");
  const [password, setPassword] = useState(
    "info@izmirbesiktaslilardernegi.org.tr"
  );
  const navigate = useNavigate();
  const toast = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getSitebilgileri();
      if (response) {
        setLogo(response[0].logo);
      }
    };
    fetchData();
  }, []);
  const handleLogin = async () => {
    try {
      if (email && password) {
        const response = await getToken(email, password);
        if (response && localStorage.getItem("token")) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: "Giriş başarılı!",
            life: 1000,
          });
          setTimeout(() => {
            navigate("/");
          }, 1000);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "E-Mail veya Şifre hatalı!",
            life: 3000,
          });
        }
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Warning",
          detail: "E-Mail ve Şifre alanı boş bırakılamaz!",
          life: 3000,
        });
      }
    } catch (error) {
      console.error("error getting user token: ", error);
    }
  };
  return (
    <Fragment>
      <Toast ref={toast} />
      <div
        className="p-grid p-justify-center p-align-center  "
        style={{ minHeight: "100vh" }}>
        <div className="p-col-12 p-md-3">
          <div className="surface-card p-4 lg:shadow-6 shadow-0  border-round">
            <div className="text-center mb-5">
              <img className="my-5" src={logo ? logo : null}></img>
              <div className="text-900 text-3xl font-medium mb-3">
                İzmir Beşiktaşlılar Derneği
              </div>
            </div>

            <div>
              <label className="block text-900 font-medium mb-2">E-Mail</label>
              <InputText
                type="text"
                placeholder="E-Mail"
                className="w-full mb-3"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyUp={(e) => (e.key === "Enter" ? handleLogin() : null)}
              />

              <label className="block text-900 font-medium mb-2">Şifre</label>
              <InputText
                type="password"
                placeholder="Şifre"
                className="w-full mb-3"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyUp={(e) => (e.key === "Enter" ? handleLogin() : null)}
              />

              <Button
                label="Giriş Yap"
                icon="pi pi-user"
                severity="danger"
                className="w-full text-gray-50"
                onClick={handleLogin}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
