import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import {
  getSitebilgileri,
  putSitebilgileri,
} from "../../services/sitebilgileriService";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";

const ApiYayinBilgileri = () => {
  const toast = useRef(null);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await getSitebilgileri();
      if (response) {
        setSettings(response[0]);
      }
    };

    fetchSettings();
  }, []);

  const submitSettings = async (settingsData) => {
    const response = await putSitebilgileri(settingsData.id, settingsData);
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Güncelleme Başarılı!",
        life: 1000,
      });
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="surface-section px-4 py-2 md:px-4 lg:px-4">
        <div className="grid">
          <div className="col-12">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">Site URL</label>
                <InputText
                  value={settings && settings.siteUrl ? settings.siteUrl : null}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      siteUrl: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">API Base URL</label>
                <InputText
                  value={
                    settings && settings.apibaseurl ? settings.apibaseurl : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      apibaseurl: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900 mb-2">
                  Google Robotları Aktifliği
                </label>
                <br />
                <InputSwitch
                  checked={settings && settings.gindex == 1 ? true : false}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      gindex: e.value,
                    });
                  }}
                />
              </div>
              <div className="field mb-4 col-12">
                <label
                  htmlFor="googleanalytics"
                  className="font-medium text-900">
                  Google Analytics
                </label>
                <InputTextarea
                  value={
                    settings && settings.googleanalytics
                      ? settings.googleanalytics
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      googleanalytics: e.target.value,
                    });
                  }}
                  rows={4}
                />
              </div>
              <div className="field mb-4 col-12">
                <label
                  htmlFor="googlesearchconsole"
                  className="font-medium text-900">
                  Google Search Console
                </label>
                <InputTextarea
                  value={
                    settings && settings.googlesearchconsole
                      ? settings.googlesearchconsole
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      googlesearchconsole: e.target.value,
                    });
                  }}
                  rows={4}
                />
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">META Pixel</label>
                <InputTextarea
                  value={
                    settings && settings.metapixel ? settings.metapixel : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      metapixel: e.target.value,
                    });
                  }}
                  rows={4}
                />
              </div>
              <hr />
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">Mobil App - CSS</label>
                <InputTextarea
                  value={
                    settings && settings.mobilappcss
                      ? settings.mobilappcss
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      mobilappcss: e.target.value,
                    });
                  }}
                  rows={4}
                />
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">Mobil App - JS</label>
                <InputTextarea
                  value={
                    settings && settings.mobilappjs ? settings.mobilappjs : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      mobilappjs: e.target.value,
                    });
                  }}
                  rows={4}
                />
              </div>
              <div className="col-12">
                <Button
                  label="Kaydet"
                  onClick={() => {
                    submitSettings(settings);
                  }}
                  className="w-auto mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ApiYayinBilgileri;
