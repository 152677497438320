import axiosInstance from "./axiosInstance";

export const getAllSponsorluklar = async () => {
  try {
    const response = await axiosInstance.get("/sponsorluklar");
    return response.data;
  } catch (error) {
    console.error("error getting sponsorluklar all: ", error);
  }
};

export const getSponsorluklar = async (id) => {
  try {
    const response = await axiosInstance.get(`/sponsorluklar/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting sponsorluklar: ${id}`, error);
  }
};

export const getAllFormsBySponsorId = async (id) => {
  try {
    const response = await axiosInstance.get(`/sponsorluk-basvurusu/${id}`);
    return response.data;
  } catch (error) {
    console.error(`error getting sponsorluklar: ${id}`, error);
  }
};

export const storeSponsorluklar = async (sponsorluklarData) => {
  try {
    const response = await axiosInstance.post(
      "/sponsorluklar",
      sponsorluklarData
    );
    return response;
  } catch (error) {
    console.error(`error getting sponsorluklar store:`, error);
  }
};

export const storeOdemeMail = async (data) => {
  try {
    const response = await axiosInstance.post(
      "/sponsorluk-basvurusu/odeme/mail-gonder",
      data
    );
    return response;
  } catch (error) {
    console.error(`error getting sponsorluklar store:`, error);
  }
};

export const putSponsorluklar = async (id, sponsorluklarData) => {
  try {
    const response = await axiosInstance.put(
      `/sponsorluklar/${id}`,
      sponsorluklarData
    );
    return response;
  } catch (error) {
    console.error(`error getting sponsorluklar update: ${id}`, error);
  }
};

export const putRangeSponsorluklar = async (sponsorluklarData) => {
  try {
    const response = await axiosInstance.put(
      `/sponsorluklar/range/update`,
      sponsorluklarData
    );
    return response;
  } catch (error) {
    console.error(`error getting sponsorluklar range update:`, error);
  }
};

export const deleteSponsorluklar = async (id) => {
  try {
    const response = await axiosInstance.delete(`/sponsorluklar/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting sponsorluklar delete: ${id}`, error);
  }
};
