import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  getSitebilgileri,
  putSitebilgileri,
} from "../../services/sitebilgileriService";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";

const IletisimBilgileri = () => {
  const toast = useRef(null);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchSettings = async () => {
      const response = await getSitebilgileri();
      if (response) {
        setSettings(response[0]);
      }
    };

    fetchSettings();
  }, []);

  const submitSettings = async (settingsData) => {
    const response = await putSitebilgileri(settingsData.id, settingsData);
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Güncelleme Başarılı!",
        life: 1000,
      });
    }
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="surface-section px-4 py-2 md:px-4 lg:px-4">
        <div className="grid">
          <div className="col-12">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">E-Mail</label>
                <InputText
                  value={settings && settings.mail ? settings.mail : null}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      mail: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">E-Mail 2</label>
                <InputText
                  value={settings && settings.mail2 ? settings.mail2 : null}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      mail2: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">Telefon</label>
                <InputText
                  value={settings && settings.tel ? settings.tel : null}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      tel: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-6">
                <label className="font-medium text-900">Telefon 2</label>
                <InputText
                  value={settings && settings.tel2 ? settings.tel2 : null}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      tel2: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-4">
                <label className="font-medium text-900">
                  Çalışma Saatleri(Hafta İçi)
                </label>
                <InputText
                  value={
                    settings && settings.calismasaatlerihici
                      ? settings.calismasaatlerihici
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      calismasaatlerihici: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-4">
                <label className="font-medium text-900">
                  Çalışma Saatleri(Cumartesi)
                </label>
                <InputText
                  value={
                    settings && settings.calismasaatlericmt
                      ? settings.calismasaatlericmt
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      calismasaatlericmt: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12 md:col-4">
                <label className="font-medium text-900">
                  Çalışma Saatleri(Pazar)
                </label>
                <InputText
                  value={
                    settings && settings.calismasaatleripzr
                      ? settings.calismasaatleripzr
                      : null
                  }
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      calismasaatleripzr: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">Adres</label>
                <InputText
                  value={settings && settings.adres ? settings.adres : null}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      adres: e.target.value,
                    });
                  }}
                  type="text"
                />
              </div>
              <div className="field mb-4 col-12">
                <label className="font-medium text-900">Harita</label>
                <InputTextarea
                  value={settings && settings.harita ? settings.harita : null}
                  onChange={(e) => {
                    setSettings({
                      ...settings,
                      harita: e.target.value,
                    });
                  }}
                  type="text"
                  rows={4}
                  autoResize
                />
              </div>
              <div className="col-12">
                <Button
                  label="Kaydet"
                  onClick={() => {
                    submitSettings(settings);
                  }}
                  className="w-auto mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default IletisimBilgileri;
