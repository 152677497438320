import React, { Fragment, useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { getUsers, putUsersPassword } from "../../services/usersService";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";

const SifreDegistir = () => {
  const toast = useRef(null);
  const [user, setUser] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchUser = async () => {
      const response = await getUsers(id);
      if (response) {
        setUser(response);
      }
    };

    fetchUser();
  }, []);

  const submitUser = async (userData) => {
    const response = await putUsersPassword(userData.id, userData);
    if (response) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Güncelleme Başarılı!",
        life: 1000,
      });
    }
  };
  return (
    <Fragment>
      <Toast ref={toast} />
      <div className="surface-section px-4 py-2 md:px-4 lg:px-4">
        <div className="grid">
          <div className="col-12">
            <div className="grid formgrid p-fluid">
              <div className="field mb-4 col-12">
                <label htmlFor="sifre" className="font-medium text-900">
                  Şifre
                </label>
                <div className="card flex justify-content-center">
                  <Password
                    value={user && user.password ? user.password : null}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        password: e.target.value,
                      });
                    }}
                    toggleMask
                  />
                </div>
              </div>
              <div className="col-12">
                <Button
                  label="Kaydet"
                  onClick={() => {
                    submitUser(user);
                  }}
                  disabled={user && !user.password}
                  className="w-auto mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SifreDegistir;
