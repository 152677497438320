import React, { useState, useEffect, Fragment, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import {
  getAllKunye,
  putKunye,
  storeKunye,
  putRangeKunye,
  deleteKunye,
} from "../../services/kunyeService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";

const Kunye = () => {
  const [kunye, setKunye] = useState(null);
  const [selectedKunye, setSelectedKunye] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    setIsLoading(true);
    const fetchBlogs = async () => {
      const response = await getAllKunye();
      if (response) {
        setKunye(response);
        setIsLoading(false);
      }
    };

    fetchBlogs();
  };

  const customBase64Uploader = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedKunye({
          ...selectedKunye,
          resim: base64data,
        });
      }
    };
  };

  const submitKunye = async (kunyeData) => {
    if (!kunyeData.id) {
      const response = await storeKunye(kunyeData);
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Kayıt Eklendi!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setKunye(null);
      }
    } else {
      const response = await putKunye(kunyeData.id, kunyeData);
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Güncelleme Başarılı!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setKunye(null);
      }
    }
  };

  const statusTemplate = (rowData) => {
    return (
      <div>
        <InputSwitch
          checked={rowData.status == 1 ? true : false}
          onChange={(e) => {
            if (rowData) {
              const updatedBlog = {
                ...rowData,
                status: e.value,
              };
              submitKunye(updatedBlog);
            }
          }}
        />
      </div>
    );
  };

  const onRowReOrder = async (event) => {
    const reordered = event.value;
    const updatedKunye = reordered.map((category, index) => {
      return { ...category, orders: index + 1 };
    });

    setKunye(updatedKunye);

    try {
      await putRangeKunye(updatedKunye);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Sıralama başarılı.",
        life: 3000,
      });
    } catch (error) {
      console.error("Reorder failed", error);
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Sıralama başarısız.",
        life: 3000,
      });
    }
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          icon="pi pi-pencil"
          className="mr-1"
          severity="primary"
          onClick={() => {
            setSelectedKunye(rowData);
            setVisibleDialog(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          onClick={() => deleteItem(rowData)}
        />
      </div>
    );
  };

  const deleteItem = (rowData) => {
    const accept = async () => {
      const response = await deleteKunye(rowData.id);
      if (response) {
        toast.current.show({
          severity: "info",
          summary: "Silindi",
          detail: "Kayıt Başarıyla Kaldırıldı.",
          life: 3000,
        });
        refresh();
      }
    };

    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "İptal Edildi",
        detail: "Silme İşlemi İptal Edildi.",
        life: 3000,
      });
    };

    confirmDialog({
      group: "templating",
      header: "İşlemi Onayla",
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-times text-6xl text-red-500"></i>
          <span>İçerik sistemden kalıcı olarak silinecektir.</span>
        </div>
      ),
      accept,
      reject,
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      acceptClassName: "bg-red-500",
    });
  };

  const dataTableHeader = (
    <Fragment>
      <div className="flex align-items-center justify-content-between">
        <div>
          <span>{kunye ? kunye.length : 0} kayıt bulundu.</span>
        </div>
        <div>
          <InputText
            type="search"
            placeholder="Arama yap..."
            size="30"
            onInput={(e) => {
              setGlobalFilterValue(e.target.value);
            }}
          />
        </div>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog group="templating" />
      <div className="py-2">
        <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
          <h3 style={{ color: "rgb(108, 117, 125)" }}>
            Ana Sayfa Sıralı Kutular
          </h3>
          <div className="mt-3 md:mt-0">
            <Button
              label="Yeni Kayıt Ekle"
              className="p-button-outlined mr-2"
              icon="pi pi-plus"
              onClick={() => {
                setVisibleDialog(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={kunye}
          reorderableRows
          onRowReorder={(e) => onRowReOrder(e)}
          header={dataTableHeader}
          globalFilter={globalFilterValue}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={isLoading}>
          <Column rowReorder style={{ width: "3em", fontSize: 5 }} />
          <Column field="orders" header="Sıra No" sortable />
          <Column
            field="resim"
            header="Resim"
            body={(row) => {
              return row && row.resim ? (
                <Image height="100" src={row.resim} preview />
              ) : null;
            }}
          />
          <Column field="baslik" header="Başlık" sortable />
          <Column field="yil" header="Yıl" sortable />
          <Column field="status" header="Aktiflik" body={statusTemplate} />
          <Column header="İşlem" body={actionTemplate} />
        </DataTable>
      </div>
      <Dialog
        header={
          selectedKunye && selectedKunye.id ? "Kayıt Düzenle" : "Kayıt Ekle"
        }
        visible={visibleDialog}
        onHide={() => {
          setVisibleDialog(false);
          setSelectedKunye(null);
        }}
        maximizable
        resizable={false}
        style={{ width: "75vw" }}>
        <div className="px-4 py-2 md:px-4 lg:px-4">
          <div className="grid">
            <div className="col-12">
              <div className="grid formgrid p-fluid">
                <div className="field mb-4 col-12">
                  <label htmlFor="avatar1" className="font-medium text-900">
                    Resim (Tavsiye Edilen Boyut: 410 x 516)
                  </label>
                  <br />
                  <Image
                    src={
                      selectedKunye && selectedKunye.resim
                        ? selectedKunye.resim
                        : null
                    }
                    className="mr-4"
                    width="250"
                    preview
                  />
                  <div className="mt-2">
                    <FileUpload
                      mode="advanced"
                      accept="image/*"
                      chooseOptions={{
                        className: "p-button-plain p-button-outlined",
                      }}
                      chooseLabel="Resim Seç"
                      customUpload
                      onSelect={customBase64Uploader}
                      onRemove={() => {
                        setSelectedKunye({
                          ...selectedKunye,
                          resim: null,
                        });
                      }}
                      onClear={() => {
                        setSelectedKunye({
                          ...selectedKunye,
                          resim: null,
                        });
                      }}
                      cancelOptions={{ label: "Temizle" }}
                      uploadOptions={{ className: "hidden" }}
                    />
                  </div>
                </div>
                <div className="field mb-4 col-6">
                  <label htmlFor="baslik" className="font-medium text-900">
                    Başlık
                  </label>
                  <InputText
                    value={
                      selectedKunye && selectedKunye.baslik
                        ? selectedKunye.baslik
                        : null
                    }
                    onChange={(e) => {
                      setSelectedKunye({
                        ...selectedKunye,
                        baslik: e.target.value,
                      });
                    }}
                    type="text"
                  />
                </div>
                <div className="field mb-4 col-6">
                  <label className="font-medium text-900">Sayfa URL</label>
                  <InputText
                    value={
                      selectedKunye && selectedKunye.link
                        ? selectedKunye.link
                        : null
                    }
                    onChange={(e) => {
                      setSelectedKunye({
                        ...selectedKunye,
                        link: e.target.value,
                      });
                    }}
                    type="text"
                  />
                </div>
                <div className="col-12">
                  <Button
                    label="Kaydet"
                    className="w-auto mt-3"
                    onClick={() => {
                      submitKunye(selectedKunye);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default Kunye;
