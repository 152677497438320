import axiosInstance from "./axiosInstance";

export const getAllUyelikFormu = async () => {
  try {
    const response = await axiosInstance.get("/uyelik-formu");
    return response.data;
  } catch (error) {
    console.error("error getting üyelik formu all: ", error);
  }
};

export const getUyelikFormu = async (id) => {
  try {
    const response = await axiosInstance.get(`/uyelik-formu/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting üyelik formu: ${id}`, error);
  }
};

export const storeUyelikFormu = async (uyelikformuData) => {
  try {
    const response = await axiosInstance.post("/uyelik-formu", uyelikformuData);
    return response;
  } catch (error) {
    console.error(`error getting üyelik formu store: `, error);
  }
};

export const putUyelikFormu = async (id, uyelikformuData) => {
  try {
    const response = await axiosInstance.put(
      `/uyelik-formu/${id}`,
      uyelikformuData
    );
    return response;
  } catch (error) {
    console.error(`error getting üyelik formu update: ${id}`, error);
  }
};

export const putRangeUyelikFormu = async (uyelikformuData) => {
  try {
    const response = await axiosInstance.put(
      `/uyelik-formu/range/update`,
      uyelikformuData
    );
    return response;
  } catch (error) {
    console.error(`error getting üyelik formu range update:`, error);
  }
};

export const deleteUyelikFormu = async (id) => {
  try {
    const response = await axiosInstance.delete(`/uyelik-formu/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting üyelik formu delete: ${id}`, error);
  }
};
