import axiosInstance from "./axiosInstance";

export const getSitebilgileri = async () => {
  try {
    const response = await axiosInstance.get(`/site-bilgileri`);
    return response.data;
  } catch (error) {
    console.error(`error getting site bilgileri: `, error);
  }
};

export const putSitebilgileri = async (id, sitebilgileriData) => {
  try {
    const response = await axiosInstance.put(
      `/site-bilgileri/${id}`,
      sitebilgileriData
    );
    return response;
  } catch (error) {
    console.error(`error getting site bilgileri update: ${id}`, error);
  }
};
