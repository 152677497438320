import React, { Fragment, useState } from "react";
import { classNames } from "primereact/utils";
import { Ripple } from "primereact/ripple";
import GenelBilgiler from "../../components/settings/GenelBilgiler";
import ApiYayinBilgileri from "../../components/settings/ApiYayinBilgileri";
import IletisimBilgileri from "../../components/settings/IletisimBilgileri";
import SosyalMedyaAdresleri from "../../components/settings/SosyalMedyaAdresleri";
import DernekHesapBilgileri from "../../components/settings/DernekHesapBilgileri";
import SponsorBannerAlanlari from "../../components/settings/SponsorBannerAlanlari";
import SmtpAyarlari from "../../components/settings/SmtpAyarlari";
import FiyatBilgileri from "../../components/settings/FiyatBilgileri";
import PopUpBanner from "../../components/settings/PopUpBanner";

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <Fragment>
      <h3 style={{ color: "rgb(108, 117, 125)" }}>Ayarlar</h3>
      <ul className="surface-card p-2 md:p-4 lg:p-4 m-0 list-none flex overflow-x-auto select-none">
        <li className="mr-2">
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 0,
                "text-700": activeTab !== 0,
              }
            )}
            onClick={() => setActiveTab(0)}>
            <span className="font-medium">Genel Bilgiler</span>
            <Ripple />
          </a>
        </li>
        <li className="mr-2">
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 1,
                "text-700": activeTab !== 1,
              }
            )}
            onClick={() => setActiveTab(1)}>
            <span className="font-medium">API ve Yayın Bilgileri</span>
            <Ripple />
          </a>
        </li>
        <li className="mr-2">
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 2,
                "text-700": activeTab !== 2,
              }
            )}
            onClick={() => setActiveTab(2)}>
            <span className="font-medium">SMTP</span>
            <Ripple />
          </a>
        </li>
        <li className="mr-2">
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 3,
                "text-700": activeTab !== 3,
              }
            )}
            onClick={() => setActiveTab(3)}>
            <span className="font-medium">İletişim Bilgileri</span>
            <Ripple />
          </a>
        </li>
        <li>
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 4,
                "text-700": activeTab !== 4,
              }
            )}
            onClick={() => setActiveTab(4)}>
            <span className="font-medium">Sosyal Medya Adresleri</span>
            <Ripple />
          </a>
        </li>
        <li>
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 5,
                "text-700": activeTab !== 5,
              }
            )}
            onClick={() => setActiveTab(5)}>
            <span className="font-medium">Dernek Hesap Bilgileri</span>
            <Ripple />
          </a>
        </li>
        <li>
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 6,
                "text-700": activeTab !== 6,
              }
            )}
            onClick={() => setActiveTab(6)}>
            <span className="font-medium">Sponsor Banner Alanları</span>
            <Ripple />
          </a>
        </li>
        <li>
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 7,
                "text-700": activeTab !== 7,
              }
            )}
            onClick={() => setActiveTab(7)}>
            <span className="font-medium">Pop-Up</span>
            <Ripple />
          </a>
        </li>
        <li>
          <a
            className={classNames(
              "p-ripple cursor-pointer px-4 py-3 flex align-items-center hover:surface-100 border-round transition-colors transition-duration-150 border-noround",
              {
                "bg-primary hover:bg-primary": activeTab === 8,
                "text-700": activeTab !== 8,
              }
            )}
            onClick={() => setActiveTab(8)}>
            <span className="font-medium">Fiyat Bilgileri</span>
            <Ripple />
          </a>
        </li>
      </ul>
      {activeTab === 0 ? (
        <GenelBilgiler />
      ) : activeTab === 1 ? (
        <ApiYayinBilgileri />
      ) : activeTab === 2 ? (
        <SmtpAyarlari />
      ) : activeTab === 3 ? (
        <IletisimBilgileri />
      ) : activeTab === 4 ? (
        <SosyalMedyaAdresleri />
      ) : activeTab === 5 ? (
        <DernekHesapBilgileri />
      ) : activeTab === 6 ? (
        <SponsorBannerAlanlari />
      ) : activeTab === 7 ? (
        <PopUpBanner />
      ) : activeTab === 8 ? (
        <FiyatBilgileri />
      ) : null}
    </Fragment>
  );
};

export default Settings;
