import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./footer";
import Main from "./main";
import Sidebar from "./sidebar";
import Header from "./header";

const Layout = () => {
  return (
    <Fragment>
      <div className="grid">
        <div className="col-12">
          <Header />
          <Main>
            <Outlet />
          </Main>
          <Footer />
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
