import React, { useState, useEffect, Fragment, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Badge } from "primereact/badge";
import {
  getAllSponsorluklar,
  putSponsorluklar,
  storeSponsorluklar,
  putRangeSponsorluklar,
  deleteSponsorluklar,
  getAllFormsBySponsorId,
  storeOdemeMail,
} from "../../services/sponsorluklarService";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { Image } from "primereact/image";
import moment from "moment";

const Sponsorluklar = () => {
  const [sponsorluklar, setSponsorluklar] = useState(null);
  const [selectedSponsorluklar, setSelectedSponsorluklar] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState(null);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useRef(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [sponsorForms, setSponsorForms] = useState({});
  const [expandedSponsorluklar, setExpandedSponsorluklar] = useState({});

  useEffect(() => {
    refresh();
  }, []);

  const refresh = async () => {
    setIsLoading(true);
    const response = await getAllSponsorluklar();
    if (response) {
      setSponsorluklar(response);
      setIsLoading(false);
    }
  };

  const customBase64Uploader = async (event) => {
    const file = event.files[0];
    const reader = new FileReader();
    const blob = await fetch(file.objectURL).then((response) =>
      response.blob()
    );

    reader.readAsDataURL(blob);

    reader.onloadend = async () => {
      const base64data = reader.result;
      if (base64data) {
        setSelectedSponsorluklar({
          ...selectedSponsorluklar,
          resim: base64data,
        });
      }
    };
  };

  const submitSponsorluklar = async (sponsorluklarData) => {
    if (!sponsorluklarData.id) {
      const response = await storeSponsorluklar(sponsorluklarData);
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Kayıt Eklendi!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setSelectedSponsorluklar(null);
      }
    } else {
      const response = await putSponsorluklar(
        sponsorluklarData.id,
        sponsorluklarData
      );
      if (response) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Güncelleme Başarılı!",
          life: 1000,
        });
        refresh();
        setVisibleDialog(false);
        setSelectedSponsorluklar(null);
      }
    }
  };

  const statusTemplate = (rowData) => (
    <div>
      <InputSwitch
        checked={rowData.status === 1}
        onChange={(e) => {
          const updatedSponsorluk = {
            ...rowData,
            status: e.value ? 1 : 0,
          };
          submitSponsorluklar(updatedSponsorluk);
        }}
      />
    </div>
  );

  const onRowReOrder = async (event) => {
    const reordered = event.value;
    const updatedSponsorluklar = reordered.map((sponsorluk, index) => ({
      ...sponsorluk,
      orders: index + 1,
    }));

    setSponsorluklar(updatedSponsorluklar);

    try {
      await putRangeSponsorluklar(updatedSponsorluklar);
      toast.current.show({
        severity: "success",
        summary: "Başarılı",
        detail: "Sıralama başarılı.",
        life: 3000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Hata",
        detail: "Sıralama başarısız.",
        life: 3000,
      });
    }
  };

  const actionTemplate = (rowData) => (
    <div className="flex">
      <Button
        icon="pi pi-pencil"
        className="mr-1"
        severity="primary"
        onClick={() => {
          setSelectedSponsorluklar(rowData);
          setVisibleDialog(true);
        }}
      />
      <Button
        icon="pi pi-trash"
        severity="danger"
        onClick={() => deleteItem(rowData)}
      />
    </div>
  );

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.current.show({
          severity: "info",
          summary: "Başarıyla Kopyalandı",
          detail: "Ödeme Linki Kopyalandı",
          life: 3000,
        });
      })
      .catch((err) => {
        console.error("Link kopyalanırken bir hata oluştu: ", err);
      });
  };

  const actionTemplateForms = (rowData, parentData) => (
    <div className="flex">
      <Button
        icon="pi pi-send"
        className="mr-1"
        severity="primary"
        disabled={parentData?.odemeyapildimi === 1}
        onClick={() => submitOdemeMail(rowData, parentData)}>
        {rowData.emailgonderildimi === 1 && <Badge value="Gönderildi"></Badge>}
      </Button>
      <Button
        icon="pi pi-clipboard"
        className="mr-1"
        severity="success"
        disabled={parentData?.odemeyapildimi === 1}
        onClick={() =>
          copyToClipboard(
            `https://www.izmirbesiktaslilardernegi.org.tr/sponsorluk-odemesi-yap?token=${rowData.token}`
          )
        }
      />
    </div>
  );

  const submitOdemeMail = async (form, parentData) => {
    const data = {
      adsoyad: form.adsoyad,
      email: form.email,
      baslik: parentData.baslik,
      token: form.token,
      sponsorlukodemesigonder: "gonderildi",
    };

    const response = await storeOdemeMail(data);

    if (response) {
      toast.current.show({
        severity: "info",
        summary: "Başarıyla Gönderildi",
        detail: "Ödeme Maili Gönderildi",
        life: 3000,
      });
      // Refresh the forms data for the sponsor
      fetchSponsorlukForms(parentData.id);
    }
  };

  const deleteItem = (rowData) => {
    const accept = async () => {
      const response = await deleteSponsorluklar(rowData.id);
      if (response) {
        toast.current.show({
          severity: "info",
          summary: "Silindi",
          detail: "Kayıt Başarıyla Kaldırıldı.",
          life: 3000,
        });
        refresh();
      }
    };

    const reject = () => {
      toast.current.show({
        severity: "warn",
        summary: "İptal Edildi",
        detail: "Silme İşlemi İptal Edildi.",
        life: 3000,
      });
    };

    confirmDialog({
      group: "templating",
      header: "İşlemi Onayla",
      message: (
        <div className="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i className="pi pi-times text-6xl text-red-500"></i>
          <span>İçerik sistemden kalıcı olarak silinecektir.</span>
        </div>
      ),
      accept,
      reject,
      acceptLabel: "Evet",
      rejectLabel: "Hayır",
      acceptClassName: "bg-red-500",
    });
  };

  const statusTemplateOdenmeDurumu = (rowData) => (
    <div>
      <Badge
        value={rowData && rowData.odemeyapildimi === 1 ? "Ödendi" : "Ödenmedi"}
        severity={
          rowData && rowData.odemeyapildimi === 1 ? "success" : "danger"
        }
      />
    </div>
  );

  const dataTableHeader = (
    <Fragment>
      <div className="flex align-items-center justify-content-between">
        <div>
          <span>{sponsorluklar ? sponsorluklar.length : 0} kayıt bulundu.</span>
        </div>
        <div>
          <InputText
            type="search"
            placeholder="Arama yap..."
            size="30"
            onInput={(e) => setGlobalFilterValue(e.target.value)}
          />
        </div>
      </div>
    </Fragment>
  );

  const rowExpansionTemplate = (data) => {
    const forms = Array.isArray(sponsorForms[data.id])
      ? sponsorForms[data.id]
      : [];

    return (
      <div className="p-3">
        <DataTable value={forms}>
          <Column
            field="created_at"
            header="Gönderildiği Tarih"
            body={(e) => moment(e.created_at).format("DD.MM.YYYY HH:mm")}
            sortable
          />
          <Column field="adsoyad" header="Ad Soyad" sortable />
          <Column field="email" header="E-Mail" sortable />
          <Column field="telefon" header="Telefon" sortable />
          <Column field="mesaj" header="Mesaj" sortable />
          <Column
            field="odemeyapildimi"
            header="Ödenme Durumu"
            body={statusTemplateOdenmeDurumu}
          />
          <Column body={(rowData) => actionTemplateForms(rowData, data)} />
        </DataTable>
      </div>
    );
  };

  const fetchSponsorlukForms = async (sponsorId) => {
    const response = await getAllFormsBySponsorId(sponsorId);
    if (response) {
      setSponsorForms((prevState) => ({
        ...prevState,
        [sponsorId]: response,
      }));
    }
  };

  const handleRowExpand = (event) => {
    const expandedRows = event.data;
    setExpandedRows(expandedRows);

    expandedRows.forEach((row) => {
      if (!sponsorForms[row.id]) {
        fetchSponsorlukForms(row.id);
      }
    });

    // Set the selected sponsorluklar state for each expanded row
    const expandedData = {};
    expandedRows.forEach((row) => {
      expandedData[row.id] = row;
    });
    setExpandedSponsorluklar(expandedData);
  };

  return (
    <Fragment>
      <Toast ref={toast} />
      <ConfirmDialog group="templating" />
      <div className="py-2">
        <div className="flex flex-column md:align-items-center md:justify-content-between md:flex-row">
          <h3 style={{ color: "rgb(108, 117, 125)" }}>Sponsorluklar</h3>
          <div className="mt-3 md:mt-0">
            <Button
              label="Yeni Kayıt Ekle"
              className="p-button-outlined mr-2"
              icon="pi pi-plus"
              onClick={() => {
                setSelectedSponsorluklar(null);
                setVisibleDialog(true);
              }}
            />
          </div>
        </div>
      </div>
      <div className="card">
        <DataTable
          value={sponsorluklar}
          reorderableRows
          onRowReorder={onRowReOrder}
          expandedRows={expandedRows}
          onRowToggle={handleRowExpand}
          rowExpansionTemplate={rowExpansionTemplate}
          header={dataTableHeader}
          globalFilter={globalFilterValue}
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={isLoading}>
          <Column expander style={{ width: "5rem" }} />
          <Column rowReorder style={{ width: "3em", fontSize: 5 }} />
          <Column field="orders" header="Sıra No" sortable />
          <Column
            field="resim"
            header="Resim"
            body={(row) =>
              row && row.resim ? (
                <Image height="100" src={row.resim} preview />
              ) : null
            }
          />
          <Column field="baslik" header="Başlık" sortable />
          <Column
            field="odemeyapildimi"
            header="Ödenme Durumu"
            body={statusTemplateOdenmeDurumu}
          />
          <Column field="status" header="Aktiflik" body={statusTemplate} />
          <Column header="İşlem" body={actionTemplate} />
        </DataTable>
      </div>
      <Dialog
        header={
          selectedSponsorluklar && selectedSponsorluklar.id
            ? "Kayıt Düzenle"
            : "Kayıt Ekle"
        }
        visible={visibleDialog}
        onHide={() => {
          setVisibleDialog(false);
          setSelectedSponsorluklar(null);
        }}
        maximizable
        resizable={false}
        style={{ width: "75vw" }}>
        <div className="px-4 py-2 md:px-4 lg:px-4">
          <div className="grid">
            <div className="col-12">
              <div className="grid formgrid p-fluid">
                <div className="field mb-4 col-12">
                  <label htmlFor="avatar1" className="font-medium text-900">
                    Resim (Tavsiye Edilen Boyut: 900 x 500)
                  </label>
                  <br />
                  <Image
                    src={
                      selectedSponsorluklar && selectedSponsorluklar.resim
                        ? selectedSponsorluklar.resim
                        : null
                    }
                    className="mr-4"
                    width="250"
                    preview
                  />
                  <div className="mt-2">
                    <FileUpload
                      mode="advanced"
                      accept="image/*"
                      chooseOptions={{
                        className: "p-button-plain p-button-outlined",
                      }}
                      chooseLabel="Resim Seç"
                      customUpload
                      onSelect={customBase64Uploader}
                      onRemove={() => {
                        setSelectedSponsorluklar({
                          ...selectedSponsorluklar,
                          resim: null,
                        });
                      }}
                      onClear={() => {
                        setSelectedSponsorluklar({
                          ...selectedSponsorluklar,
                          resim: null,
                        });
                      }}
                      cancelOptions={{ label: "Temizle" }}
                      uploadOptions={{ className: "hidden" }}
                    />
                  </div>
                </div>
                <div className="field mb-4 col-12">
                  <label htmlFor="baslik" className="font-medium text-900">
                    Başlık
                  </label>
                  <InputText
                    value={
                      selectedSponsorluklar && selectedSponsorluklar.baslik
                        ? selectedSponsorluklar.baslik
                        : ""
                    }
                    onChange={(e) => {
                      setSelectedSponsorluklar({
                        ...selectedSponsorluklar,
                        baslik: e.target.value,
                      });
                    }}
                    type="text"
                  />
                </div>
                <div className="field mb-4 col-6">
                  <label className="font-medium text-900">Fiyat</label>
                  <InputText
                    value={
                      selectedSponsorluklar && selectedSponsorluklar.fiyat
                        ? selectedSponsorluklar.fiyat
                        : ""
                    }
                    onChange={(e) => {
                      setSelectedSponsorluklar({
                        ...selectedSponsorluklar,
                        fiyat: e.target.value,
                      });
                    }}
                    type="text"
                  />
                </div>
                <div className="field mb-4 col-6">
                  <label className="font-medium text-900">Ödenme Durumu</label>
                  <br />
                  <InputSwitch
                    checked={
                      selectedSponsorluklar &&
                      selectedSponsorluklar.odemeyapildimi === 1
                    }
                    onChange={(e) =>
                      setSelectedSponsorluklar({
                        ...selectedSponsorluklar,
                        odemeyapildimi: e.value ? 1 : 0,
                      })
                    }
                  />
                </div>
                <div className="field mb-4 col-12">
                  <label htmlFor="seo" className="font-medium text-900">
                    SEO
                  </label>
                  <InputTextarea
                    value={
                      selectedSponsorluklar && selectedSponsorluklar.seo
                        ? selectedSponsorluklar.seo
                        : ""
                    }
                    onChange={(e) => {
                      setSelectedSponsorluklar({
                        ...selectedSponsorluklar,
                        seo: e.target.value,
                      });
                    }}
                    type="text"
                    rows={4}
                    autoResize={false}
                    style={{ resize: "vertical", overflow: "auto" }}
                  />
                </div>
                <div className="field mb-4 col-12">
                  <label htmlFor="yazi" className="font-medium text-900">
                    Yazı
                  </label>
                  <Editor
                    autoResize={false}
                    style={{
                      width: "100%",
                      minHeight: 150,
                      resize: "vertical",
                      overflow: "auto",
                    }}
                    value={
                      selectedSponsorluklar && selectedSponsorluklar.yazi
                        ? selectedSponsorluklar.yazi
                        : ""
                    }
                    onTextChange={(e) => {
                      setSelectedSponsorluklar({
                        ...selectedSponsorluklar,
                        yazi: e.htmlValue,
                      });
                    }}
                  />
                </div>
                <div className="col-12">
                  <Button
                    label="Kaydet"
                    className="w-auto mt-3"
                    onClick={() => submitSponsorluklar(selectedSponsorluklar)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
};

export default Sponsorluklar;
