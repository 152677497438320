import axiosInstance from "./axiosInstance";

export const getAllYoneticiler = async () => {
  try {
    const response = await axiosInstance.get("/yoneticiler");
    return response.data;
  } catch (error) {
    console.error("error getting yöneticilerimiz all: ", error);
  }
};

export const getYoneticiler = async (id) => {
  try {
    const response = await axiosInstance.get(`/yoneticiler/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting yöneticilerimiz: ${id}`, error);
  }
};

export const storeYoneticiler = async (yoneticilerimizData) => {
  try {
    const response = await axiosInstance.post(
      "/yoneticiler",
      yoneticilerimizData
    );
    return response;
  } catch (error) {
    console.error(`error getting yöneticilerimiz store: `, error);
  }
};

export const putYoneticiler = async (id, yoneticilerimizData) => {
  try {
    const response = await axiosInstance.put(
      `/yoneticiler/${id}`,
      yoneticilerimizData
    );
    return response;
  } catch (error) {
    console.error(`error getting yöneticilerimiz update: ${id}`, error);
  }
};

export const deleteYoneticiler = async (id) => {
  try {
    const response = await axiosInstance.delete(`/yoneticiler/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting yöneticilerimiz delete: ${id}`, error);
  }
};
