import axiosInstance from "./axiosInstance";

export const getAllKunye = async () => {
  try {
    const response = await axiosInstance.get("/kunye");
    return response.data;
  } catch (error) {
    console.error("error getting künye all: ", error);
  }
};

export const getKunye = async (id) => {
  try {
    const response = await axiosInstance.get(`/kunye/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting künye: ${id}`, error);
  }
};

export const storeKunye = async (kunyeData) => {
  try {
    const response = await axiosInstance.post("/kunye", kunyeData);
    return response;
  } catch (error) {
    console.error(`error getting künye store: `, error);
  }
};

export const putKunye = async (id, kunyeData) => {
  try {
    const response = await axiosInstance.put(`/kunye/${id}`, kunyeData);
    return response;
  } catch (error) {
    console.error(`error getting künye update: ${id}`, error);
  }
};

export const putRangeKunye = async (kunyeData) => {
  try {
    const response = await axiosInstance.put(`/kunye/range/update`, kunyeData);
    return response;
  } catch (error) {
    console.error(`error getting künye range update:`, error);
  }
};

export const deleteKunye = async (id) => {
  try {
    const response = await axiosInstance.delete(`/kunye/${id}`);
    return response;
  } catch (error) {
    console.error(`error getting künye delete: ${id}`, error);
  }
};
